import requestService from '../requestService';

const prefix = '/crm';

export default {
  async getCrmOrderList(params = {}) {
    const response = await requestService.get(`${prefix}/orders/list`, {
      ...params,
      order: 'DESC',
      orderby: 'time_created',
      uselike: true,
      show_total: true
    });
    return response?.data;
  },
  async getCrmOrderItem(params) {
    const response = await requestService.get(`${prefix}/orders/info/${params}`);
    return response?.data?.object;
  },
  async addCrmUserState(payload) {
    const response = await requestService.post(`${prefix}/states/add`, payload);
    return response?.data?.object;
  },
  async updateCrmUserState(payload) {
    const response = await requestService.post(`${prefix}/states/update`, payload, {}, false);
    return response?.data?.object;
  },
  async getCrmUserStateList(payload,params) {
    const response = await requestService.get(`${prefix}/states/list?orderby=position&uuid_board=${payload}`,params);
    return response?.data?.object;
  },
  async setUserState(payload) {
    const response = await requestService.post(`${prefix}/orders/setstate`, payload);
    return response?.data?.object;
  },
  async addCrmOrder(payload) {
    const response = await requestService.post(`${prefix}/orders/add`, payload);
    return response?.data?.object;
  },
  async updateCrmOrder(payload) {
    const response = await requestService.post(`${prefix}/orders/update`, payload);
    return response?.data?.object;
  },
  async startCrmOrderDelivery(payload) {
    const response = await requestService.get(`${prefix}/orders/delivery/start/${payload}`);
    return response?.data;
  },
  async addCrmItemAddProduct(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/orders/items/add_product`, payload, {}, false);
  },
  async addCrmItemProduct(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/orders/items/add`, payload, {}, false);
  },
  async ChangeItemPrice(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/orders/items/price`, payload, {}, false);
  },
  async getCrmItemDetailsUuid(uuid) {
    const response = await requestService.get(`${prefix}/orders/items/list`, {
      uuid_order: uuid,
      // id_state: 810,
    });
    //
    return response?.data;
  },
  async deleteCrmItem(itemUuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/orders/items/delete/${itemUuid}`, {}, false);
  },
  // Board CRM -----------------------------------------------------
  async getCrmBoardsList() {
    // eslint-disable-next-line no-return-await
    const response = await requestService.get(`${prefix}/boards/list`);
    //
    return response.data.object;
  },
  async getCrmBoardsItemInfo(itemUuid) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.get(`${prefix}/boards/info/${itemUuid}`);
    return response.data.object;
  },
  async addCrmBoardsItem(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/boards/add`, payload);
  },
  async updateCrmBoardsItem(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/boards/update`, payload);
  },
  async shareCrmBordByEmail(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.post(`${prefix}/boards/share/add/email`, payload, {}, false);
    return response?.data?.object;
  },
  async checkSharedUserByEmail(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.get(`${prefix}/boards/share/add/check`, payload);
    return response?.data?.object;
  },
  async shareCrmBordUserList(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.get(`${prefix}/boards/share/list`, payload,);
    return response?.data?.object;
  },
  async shareCrmBordUserUpdate(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.post(`${prefix}/boards/share/update`, payload,);
    return response?.data?.object;
  },
  async shareCrmBordDeleteUser(uuidUser) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.delete(`${prefix}/boards/share/delete/${uuidUser}`);
    return response?.data?.object;
  },
  async ChangeItemValue(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.post(`${prefix}/orders/items/amount`,payload);
    return response?.data?.object;
  },
  async isReadyToDeliver(uuid) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.get(`${prefix}/orders/ready_to_deliver/${uuid}`);
    return response?.data?.object;
  },
  async getItemsChipList(uuid){
    const response = await requestService.get(`/deports/products/list/chips?uuid_board=${uuid}`)
    return response?.data?.object;
  },
  async getItemsChipListCRM(uuid){
    const response = await requestService.get(`/products/list/chips`)
    return response?.data?.object;
  },
  async crmProductList(payload){
    const response = await requestService.get(`/deports/products/list`,payload)
    return response?.data?.object;
  },
  async onlyCrmProductList(payload){
    const response = await requestService.get(`/products/list`,payload)
    return response?.data?.object;
  },
  async crmStats(payload){
    const response = await requestService.get(`${prefix}/statistic/states`,payload)
    return response?.data?.object;
  },
  async crmStatsAssigned(payload){
    const response = await requestService.get(`${prefix}/statistic/assigned`,payload)
    return response?.data?.object;
  },
  async crmStatsProfite(payload){
    const response = await requestService.get(`${prefix}/statistic/profit`,payload)
    return response?.data?.object;
  },
  async getLeadSpeed(payload){
    const response = await requestService.get(`${prefix}/statistic/speed/leads`,payload)
    return response?.data;
  },
  async getTagStats(payload){
    const response = await requestService.get(`${prefix}/statistic/tags`,payload)
    return response?.data;
  },
  async crmUTMlist(payload){
    const response = await requestService.get(`${prefix}/boards/utm/list`,payload)
    return response?.data?.object;
  },
  async startAssign(uuid){
    const response = await requestService.get(`${prefix}/orders/delivery/processing/start/${uuid}`)
    return response?.data?.object;
  },
  async getTagsList(payload){
    const response = await requestService.get(`${prefix}/orders/tags/list`, payload)
    return response?.data?.object;
  },
  async createTag(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.post(`${prefix}/orders/tags/add`,payload, {}, false);
    return response?.data?.object;
  },
  async updateTag(payload) {
    // eslint-disable-next-line no-return-await
    const response = await requestService.post(`${prefix}/orders/tags/update`, payload, {}, false);
    return response?.data?.object;
  },
  async deleteTag(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/orders/tags/delete/${uuid}`);
  },
  async getTagsTemplateList(payload){
    const response = await requestService.get(`${prefix}/orders/tags/templates/list`, payload)
    return response?.data?.object;
  },
  async deleteTagsTemplateList(uuid){
    const response = await requestService.delete(`${prefix}/orders/tags/templates/delete/${uuid}`)
    return response?.data?.object;
  },
  async updateTagsTemplateList(payload){
    const response = await requestService.post(`${prefix}/orders/tags/templates/update`,payload)
    return response?.data?.object;
  },
  async createTagsTemplateList(payload){
    const response = await requestService.post(`${prefix}/orders/tags/templates/add`,payload)
    return response?.data?.object;
  },
  async getStatisticTags(payload){
    const response = await requestService.get(`${prefix}/statistic/dashboard`,payload)
    return response?.data?.object;
  },
  async buyBalance(payload){
    const response = await requestService.post(`/services/buy`,payload)
    return response?.data?.object;
  },
};
