// eslint-disable-next-line import/no-cycle
import requestService from '../requestService';

const prefix = '/users';

export default {
  async getClientsList(params = {},type = 'client') {
    const response = await requestService.get(`${prefix}/list`, {
      type: type,
      ...params,
    });
    return response?.data?.object;
  },
  async getEmployeesList(params = {}) {
    const response = await requestService.get(`${prefix}/list`, {
      type: 'employee',
      ...params,
    });
    return response?.data?.object;
  },
  async getManagersList(params = {}) {
    const response = await requestService.get(`${prefix}/list`, {
      type: 'manager',
      ...params,
    });
    return response?.data?.object;
  },
  async getAdminsList(params = {}) {
    const response = await requestService.get(`${prefix}/list`, {
      type: 'admin',
      ...params,
    });
    return response?.data?.object;
  },
  async getOwnersList(params = {}) {
    const response = await requestService.get(`${prefix}/list`, {
      type: 'owner',
      ...params,
    });
    return response?.data?.object;
  },
  async deleteUser(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${uuid}`);
  },
  async addUser(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/add`, payload, {}, false);
  },
  async updateUser(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/update`, payload);
  },
  async activateUser(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.get(`${prefix}/activate/force/${uuid}`);
  },
  async getUserByUUID(uuid) {
    const response = await requestService.get(`${prefix}/list?uuid=${uuid}`);
    return response?.data?.object;
  },
  async getUserUUID(uuid) {
    //
    const response = await requestService.get(`${prefix}/info`);
    return response?.data?.object;
  },
  async userPasswordChange(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/password/change`, payload,{},false);
  },
  async userPasswordReset(payload) {
    // eslint-disable-next-line no-return-await
    return await requestService.post(`${prefix}/password/reset/new`, payload, {}, false);
  },
  async changeAccountLanguage(payload){
    const response = await requestService.put(`${prefix}/lang/${payload}`);
    return response?.data?.object;
  }
};
