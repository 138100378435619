import { mapGetters, mapActions } from 'vuex';
import * as getterTypes from '../store/modules/imagesCheckbox/types/getters';
import * as actionTypes from '../store/modules/imagesCheckbox/types/actions';

export default {
    methods: {
        ...mapActions('imagesCheckbox', {
            fetchCrmCheck: actionTypes.FETCH_CRM_CHECK,
        }),
        ...mapActions('imagesCheckbox', {
            fetchDeliveriesCheck: actionTypes.FETCH_DELIVERIES_CHECK,
        }),
    },
    computed: {
        ...mapGetters('imagesCheckbox', {
            crmImagesCheck: getterTypes.GET_CRM_CHECK,
        }),
        ...mapGetters('imagesCheckbox', {
            deliveriesImagesCheck: getterTypes.GET_DELIVERIES_CHECK,
        }),
    },
};
