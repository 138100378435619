<template>
  <div>
    <v-data-table
      fixed-header
      :headers="headers"
      :items="items"
      item-key="uuid"
      hide-default-footer
      :items-per-page="items.length"
      dense
      locale="uk"
      class="my-table supply"
      height="425px"
      :no-data-text="$t('table.noData')"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.icon="{ item }">
        <div class="w-0">
          <VBtn
            color="#F08080"
            x-small
            :disabled="blockedItem"
            @click="$emit('onClickDelete', item.uuid)"
            icon
          >
            <VIcon>mdi-delete</VIcon>
          </VBtn>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.image="{ item }">

        <img
          v-if="!item.product_details.path_img_thumb"
          :src="require('@/assets/images/supply-item.png')"
          class="image-placeholder  sm "
        />
          <v-img
          v-else
          :src="item.product_details.path_img_thumb"
          class="image  sm "
          :style="item.is_deport_product == '1' ?  'border:1px dashed red' : 'border:1px dashed grey' "
          @click="showImgDialog(item.product_details.path_img)"
          >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
              indeterminate
              :size="20"
              :width="2"
              color="green lighten-1"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>

      </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.product_details.name="{ item }" v-if="settingDialog == 'crm'">
        {{ item.product_details.name }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <VIcon :color="item.source == 'DEPORT' ? 'primary darken-1' : 'success darken-1'" v-bind="attrs" v-on="on" small>
              {{ item.source == "DEPORT" ? "mdi-warehouse" : "mdi-view-dashboard" }}
            </VIcon>
          </template>
          {{ item.source == "DEPORT" ? $t("crm.source_deport") : $t("crm.source_crm") }}
        </v-tooltip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.options="{ item }">
        {{ item.option_details.name || "--" }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.product_details.bar_code="{ item }">
        <div @click="copyToClipboard(item.product_details.bar_code)" class="copyTxt">
          {{ item.product_details.bar_code || "--" }}
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.items_amount="{ item }">
        <div v-if="isChangeAmount && !item.ns_code">
          <ns-counter
            class="my-2 mx-auto"
            v-model="item.items_amount"
            :min="1"
            :max="settingDialog == 'supply' ? getMax(item) : 9999"
            @change="onChangeCounter(item)"
          />
        </div>
        <div v-if="!isChangeAmount && !item.ns_code">
          {{ item.items_amount || 0 }}
        </div>
        <div v-if="item.ns_code">
          <div @click="copyToClipboard(item.ns_code)" class="copyTxt">
            {{ 'NS :' }} {{ item.ns_code }}
          </div>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.items_available="{ item }">
        <div
          v-if="!item.ns_code"
          :class="{
            'error--text font-weight-bold':
              isChangeAmount && item.items_available < item.items_amount && settingDialog == 'crm'
          }"
        >
          {{ item.items_available || 0 }}
        </div>
        <div
          v-if="item.ns_code"
          :class="{
            'error--text font-weight-bold':
              isChangeAmount && item.items_available < item.items_amount && settingDialog == 'crm'
          }"
        >
          {{ item.items_available || '--' }}
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.additional_sell_amount="{ item }">
        <div class="w-0">
          <VIcon v-if="item.additional_sell_amount > 0" dense>
            mdi-check
          </VIcon>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.item_price="{ item }">
        <div v-if="isChangeAmount" >
          <div
            class=" dashed-preview"
            @click="
              editPriceinput.visible = true;
              editPriceinput.uuid = item.uuid;
            "
            v-if="!editPriceinput.visible || editPriceinput.uuid != item.uuid"
          >
          <v-tooltip bottom :color="priceConfig[getPriceStatus(item)].color">
            <template v-slot:activator="{ on, attrs }">
              <VIcon
                v-bind="attrs"
                v-on="on"
                small
                :color="priceConfig[getPriceStatus(item)].color"
                >
                  {{ priceConfig[getPriceStatus(item)].icon }}
              </VIcon>
            </template>
            <span class="cursor-pointer">
              {{ $t(`table.price_info.${priceConfig[getPriceStatus(item)].tooltip}`) }}
            </span>
          </v-tooltip>
           <div class="px-1">
            {{ item.item_price || "0.00" }}
           </div>
          </div>
          <v-row
            v-if="editPriceinput.visible && editPriceinput.uuid === item.uuid"
            justify="center"
            style="width:110px"
            class="mx-auto mx-1"
          >
            <v-text-field
              dense
              autofocus
              class="dashed-input"
              :min="0"
              hide-details="auto"
              elevation="0"
              v-model.number="item.item_price"
              @keypress="validationForPrice($event)"
              @paste="validationForPrice($event)"
              ref="inputName"
              :disabled="editPriceinput.loading"
              @blur="onCloseByBlur(item.uuid)"
              :color="priceConfig[getPriceStatus(item)].color"
              :maxLength="6"
              @keydown.enter.prevent="onClosePriceEdit(item.uuid)"
            >
              <template v-slot:append>
                <VBtn
                  icon
                  dense
                  :disabled="editPriceinput.loading"
                  color="yellow darken-2"
                  @click="onClosePriceEdit(item.uuid)"
                >
                  <VIcon dense>mdi-check-circle-outline</VIcon>
                </VBtn>
              </template>
            </v-text-field>
          </v-row>
        </div>
        <div v-else>
          {{ item.item_price || "0.00" }}
        </div>
      </template>
                    <!-- eslint-disable-next-line  -->
      <template v-slot:item.item_price_extra="{ item }">
        <div v-if="isChangeAmount" >
          <div
            class=" dashed-preview"
            @click="
              editPriceinputExtra.visible = true;
              editPriceinputExtra.uuid = item.uuid;
            "
            v-if="!editPriceinputExtra.visible || editPriceinputExtra.uuid != item.uuid"
          >
            {{ item.item_price_extra || "0.00" }}
          </div>
          <v-row
            v-if="editPriceinputExtra.visible && editPriceinputExtra.uuid === item.uuid"
            justify="center"
            style="width:110px"
            class="mx-auto mx-1"
          >
            <v-text-field
              dense
              autofocus
              class="dashed-input"
              :min="0"
              hide-details="auto"
              elevation="0"
              v-model.number="item.item_price_extra"
              @keypress="validationForPrice($event)"
              @paste="validationForPrice($event)"
              ref="inputName"
              :disabled="editPriceinputExtra.loading"
              @blur="onCloseByBlur(item.uuid,'extra')"
              color="success"
              :maxLength="6"
              @keydown.enter.prevent="onClosePriceEditExtra(item.uuid)"
            >
              <template v-slot:append>
                <VBtn
                  icon
                  dense
                  :disabled="editPriceinputExtra.loading"
                  color="yellow darken-2"
                  @click="onClosePriceEditExtra(item.uuid)"
                >
                  <VIcon dense>mdi-check-circle-outline</VIcon>
                </VBtn>
              </template>
            </v-text-field>
          </v-row>
        </div>
        <div v-else>
          {{ item.item_price_extra || "0.00" }}
        </div>
      </template>
      <template v-slot:[`item.total_price`]="{ item }">
        {{ item.total_price.toFixed(2) }}
      </template>
      <template v-slot:footer v-if="this.items.length && settingDialog === 'crm'">
        <hr />
        <v-row no-gutters class="pb-1" style="height:auto;">
          <v-tooltip bottom right class="ml-auto">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="ml-auto"
                v-on="on"
                v-bind="attrs"
              >
                {{ getTotalPrice() }}
              </div>
            </template>
            <div>
              {{ getInitPrice() }}
              <br />
              {{ getProfit() }}
            </div>
          </v-tooltip>
        </v-row>
      </template>
    </v-data-table>
    <img-dialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
    <ConfirmDialog
      :visible="confirmDialog"
      @close="confirmDialog = false"
      @onConfirm="onChangePrice(chosenUUIDPrice);confirmDialog = false;"
      @onCancel="
        typeConfirmDialog = null;
        stopBlur = false;
        confirmDialog = false;
      "
      :typeAlert="'warning'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.editPrice.title") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.editPrice.desc") }}
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import ConfirmDialog from "../../../dialog/ConfirmDialog.vue";
import imgDialog from "../../../products/imgDialog.vue";
import user from "@/mixins/user";
import SizeUi from "@/mixins/SizeUi";
import {validationForPrice} from "@/mixins/helpers";

export default {
  components: { imgDialog, ConfirmDialog },
  mixins: [notifications],
  data: () => ({
    tranferAmount:0,
    imgpath: "",
    imgDialog: false,
    headers: [],
    editPriceinput: {
      visible: false,
      uuid: null,
      loading: false
    },
    editPriceinputExtra: {
      visible: false,
      uuid: null,
      loading: false
    },
    chosenUUIDPrice: "",
    confirmDialog: false,
    typeConfirmDialog:null,
    stopBlur: false,
    priceConfig:{
      min:{
        color:'error',
        icon:'mdi-thumb-down',
        tooltip:'bad_price'
      },
      max:{
        color:"orange darken-1",
        icon:'mdi-currency-uah',
        tooltip:'nice_price'
      },
      normal:{
        color:"success",
        icon:'mdi-thumb-up',
        tooltip:'normal_price'
      },
      none:{
        color:"error",
        icon:'mdi-help',
        tooltip:'no_info_price'
      },
    }
  }),
  props: {
    isAccessDeportInfo: {
      require: false
    },
    totalChosenProducts: {
      require: false
    },
    resetEditPriceTable: {
      require: false
    },
    resetCopyItems: {
      require: false
    },
    settingDialog: {
      require: false
    },
    items: {
      require: true,
      default: []
    },
    type: {
      require: true
    },
    loadingItems: {
      require: true
    },
    isImgLoaded: {
      require: true
    },
    blockedItem: {
      require: true
    },
    watchDelivery: {
      require: true
    },
    isChangeAmount: {
      require: false,
      default: false
    }
  },
  methods: {
    validationForPrice,
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
    getPriceStatus(item){
      //  eslint-disable-next-line
      const price = Number(item.item_price);
      //  eslint-disable-next-line
      const priceMin = Number(item.price_min)
      //  eslint-disable-next-line
      const priceMax = Number(item.price_max)

      if(priceMin === null || price === null || priceMax === null || price === 0){
        return "none"
      }
      if(price < priceMin){
        return 'min'
      }else if(price <= priceMax ){
        return 'normal'
      }
      else{
        return 'max'
      }
    },
    getPriceColor(item){
      const copyItem = this.copyItems.find(e => e.uuid === item.uuid)
      if(!copyItem) return;
      const copyPrice = Number(copyItem.item_price);
      const price = Number(item.item_price);
      if(price < copyPrice){
        return "error"
      }else if(price > copyPrice){
        return "success"
      }
    },
    getTotalPrice() {
      if (this.items.length && this.settingDialog === "crm") {
        const str = this.totalChosenProducts.split(",")[1];
        const text = str.split(":")[0].replace(/ /g, "");
        const number = str.split(":")[1];

        return `${this.$t(`table.${text}`)} - ${Number(number).toFixed(2)} ${this.$t("paymentPlan.uah")}`;
      } else {
        return "--";
      }
    },
    getInitPrice() {
      if (this.items.length && this.settingDialog === "crm") {
        const str = this.totalChosenProducts.split(",")[2];

        const text = str.split(":")[0].replace(/ /g, "");
        const number = str.split(":")[1];

        return `${this.$t(`table.${text}`)} - ${number} ${this.$t("paymentPlan.uah")}`;
      } else {
        return "--";
      }
    },
    getProfit() {
      if (this.items.length && this.settingDialog === "crm") {
        const str = this.totalChosenProducts.split(",")[3];

        const text = str.split(":")[0].replace(/ /g, "");
        const number = str.split(":")[1];

        return `${this.$t(`table.${text}`)} - ${number} ${this.$t("paymentPlan.uah")}`;
      } else {
        return "--";
      }
    },
    setCopyItems() {
      this.copyItems = JSON.parse(JSON.stringify(this.items));
    },
    // eslint-disable-next-line
    getMax(product_details) {
      // eslint-disable-next-line
      const item = this.copyItems.find(copyItem => copyItem.product_details.name === product_details.name);
      // eslint-disable-next-line
      const productCopy = Object.assign({}, product_details)
      if (item) {
        return this.amountBeforeChange?.items_amount || this.items_amount + item.items_available;
      } else {
        // eslint-disable-next-line
        return Number(productCopy.items_amount || this.amountBeforeChange?.items_amount) + Number(productCopy.items_available);
      }
    },

    onCloseByBlur(uuid,type = null) {
      if (!this.stopBlur) {
        if(type === 'extra'){
          this.onClosePriceEditExtra(uuid)
        }else{
          this.onClosePriceEdit(uuid);
        }
      }
    },
    onClosePriceEdit(uuid) {
      this.stopBlur = true;
      const priceBefore = this.copyItems.find(copyItem => copyItem.uuid === uuid);
      const changedItem = this.items.find(copyItem => copyItem.uuid === uuid);
      if (priceBefore?.item_price === changedItem.item_price) {
        this.editPriceinput = {
          visible: false,
          uuid: null,
          loading: false
        };
        this.stopBlur = false;
        return false;
      }
      this.confirmDialog = true;
      this.chosenUUIDPrice = uuid;
      // this.onChangePrice(uuid);
    },
    onClosePriceEditExtra(uuid) {
      this.stopBlur = true;
      const priceBefore = this.copyItems.find(copyItem => copyItem.uuid === uuid);
      const changedItem = this.items.find(copyItem => copyItem.uuid === uuid);

      if (priceBefore?.item_price_extra === changedItem.item_price_extra) {
        this.editPriceinputExtra = {
          visible: false,
          uuid: null,
          loading: false
        };
        this.stopBlur = false;

        return false;
      }
      this.typeConfirmDialog = 'extra'
      this.confirmDialog = true;
      this.chosenUUIDPrice = uuid;
      // this.onChangePrice(uuid);
    },
    onKeyChangePrice($event, item,type = null) {
      //  eslint-disable-next-line

      if(type === "extra"){
        this.$checkNumberFloat(item.item_price_extra, $event);
        if ($event.keyCode === 13) {
          this.onClosePriceEditExtra(item.uuid);
        }
      }else {
        this.$checkNumberFloat(item.item_price, $event);
        if ($event.keyCode === 13) {
          this.onClosePriceEdit(item.uuid);
        }
      }
    },
    async onChangePrice(uuid) {
      this.editPriceinput.loading = true;
      this.priceBeforeChange = this.copyItems.find(copyItem => copyItem.uuid === uuid);
      const changedItem = this.items.find(copyItem => copyItem.uuid === uuid);
      const params = {
        item: changedItem
      };
      if (this.priceBeforeChange && this.typeConfirmDialog === 'extra') {
        params.priceBeforeChange = this.priceBeforeChange?.item_price_extra;
      }else if(this.priceBeforeChange){
        params.priceBeforeChange = this.priceBeforeChange?.item_price;

      }
      this.$emit("onChangePrice", params, this.typeConfirmDialog);
      this.typeConfirmDialog = null;
    },
    onChangeCounter(item) {
      this.amountBeforeChange = this.copyItems.find(copyItem => copyItem.uuid === item.uuid);
      const params = {
        item: item
      };
      if (this.amountBeforeChange) {
        params.amountBeforeChange = this.amountBeforeChange?.items_amount;
      }
      this.$emit("onChangeCounter", params);
      this.setCopyItems()
    },
    showImgDialog(image) {
      this.imgpath = image;
      this.imgDialog = true;
    }
  },
  mounted() {
    this.setCopyItems();
    this.headers = [
      {
        text: this.$t("table.photo"),
        align: "center",
        sortable: false,
        value: "image"
      },
      {
        text: this.$t("table.name"),
        align: "start",
        value: "product_details.name"
      },

      {
        text: this.$t("table.option"),
        align: "start",
        sortable: false,
        value: "options"
      },
      {
        text: this.$t("table.amount"),
        align: "center",
        value: "items_amount"
      }
    ];
    if (this.isAccessDeportInfo || this.settingDialog !== "crm") {
      this.headers[this.headers.length] = {
        text: this.$t("supply.available"),
        align: "center",
        value: "items_available",
        width: "5px",
        sortable: false
      };
    }
    if (this.settingDialog === "supply") {
      this.headers.splice(4, 0, {
        text: this.$t("table.bar_code"),
        align: "start",
        sortable: false,
        value: "product_details.bar_code"
      });
    }
    if (this.settingDialog === "crm") {
      this.headers.splice(
        1,0,
        {
        text: this.$t("table.additional_sell"),
        align: "center",
        sortable: false,
        value: "additional_sell_amount"
      }
      );
      this.headers.push({
        text: `${this.$t("table.price")} (${this.$t('paymentPlan.uah')})`,
        align: "center",
        sortable: false,
        value: "item_price"
      });
      this.headers.push({
        text: `${this.$t("table.item_price_extra")} (${this.$t('paymentPlan.uah')})`,
        align: "center",
        sortable: false,
        value: "item_price_extra"
      });
      this.headers.push({
        text: `${this.$t("table.sum")} (${this.$t('paymentPlan.uah')})`,
        align: "center",
        sortable: false,
        value: "total_price"
      });
    }
    if (this.watchDelivery) {
      this.headers.push({
        text: this.$t("table.action"),
        align: "center",
        sortable: false,
        value: "icon"
      });
    }
  },
  watch: {
    watchDelivery: {
      deep: true,
      handler(e) {
        if (this.watchDelivery) {
          this.headers.unshift({
            text: this.$t("table.action"),
            align: "center",
            sortable: false,
            value: "icon"
          });
        } else {
          this.headers = this.headers.filter(header => header.value !== "icon");
        }
      }
    },
    "editPriceinput.uuid": {
      deep: true,
      handler(newUUID, oldUUID) {
        if (oldUUID) {
          // this.onClosePriceEdit(oldUUID)
        }
      }
    },
    resetCopyItems: {
      deep: true,
      handler(e) {
        this.setCopyItems();
      }
    },
    resetEditPriceTable: {
      deep: true,
      handler(e) {
        this.editPriceinput = {
          visible: false,
          uuid: null,
          loading: false
        };
        this.editPriceinputExtra = {
          visible: false,
          uuid: null,
          loading: false
        };
        this.stopBlur = false;
      }
    }
  }
};
</script>

<style lang="scss" >
.dashed {
  &-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #e3e3e3;
    min-width: 100px;
    height: 30px;
    border-radius: 5px;
    cursor: text;
    margin: auto;
    &:hover {
      border: 2px dashed #c4c1c1;
    }
  }
  &-input {
    min-width: 100px;
    height: 30px;
  }
}
.text-green input {
      color: green !important;
    }
</style>
