// eslint-disable-next-line import/no-cycle
/* eslint-disable import/no-cycle */
import * as actions from './types/actions';
import * as mutations from './types/mutations';

export default {
    [actions.FETCH_CRM_ITEMS_CHECK]: async ({ commit }, value = true) => {
        commit(mutations.SET_CRM_ITEMS_CHECK, value);
    },
    [actions.FETCH_DELIVERIES_ITEMS_CHECK]: async ({ commit }, value = true) => {
        commit(mutations.SET_DELIVERIES_ITEMS_CHECK, value);
    },
};
