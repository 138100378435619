<template>
  <VApp>
    <Notifications v-if="isOnline"/>
    <RouterView/>
  </VApp>
</template>

<script>
import Notifications from './components/common/notifications/Notifications.vue';
import notifications from "@/mixins/notifications";


export default {
  name: 'App',
  components: {
    Notifications,
  },
  data: () => ({
    online: null
  }),
  mixins: [notifications],
  mounted() {
    this.online = this.isOnline
  },
  computed: {
  }
};
</script>
<style lang="scss">
.norifyOnline {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1111;
  color: #fff;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  height: 56px;
  background-color: red;
  svg{
    margin-left: 10px;
    width: 25px;
    height: auto;
    path{
      fill: #fff;
    }
  }
}

</style>
