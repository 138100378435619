<template>
  <VBtn
    text
    :icon="$vuetify.breakpoint.mobile"
    class="px-md-2 mx-1"
    :outlined="isOutlined"
    :small="$vuetify.breakpoint.mobile"
    @click="onClickTheme"
  >
    <VIcon :dense="$vuetify.breakpoint.mobile">mdi-theme-light-dark</VIcon>
  </VBtn>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CountryFlag from "vue-country-flag";
import * as actionTypes from "@/store/modules/theme/types/actions";
import theme from "@/mixins/theme";
import {THEMES} from "@/mixins/constants";


export default {
  components: { CountryFlag },
  mixins:[theme],
  name: "Theme",
  data:() => ({
  }),
  props: {
    isOutlined: {
      require: false,
      default: true
    }
  },
  methods: {
    ...mapActions("theme", {
      setIsDark: actionTypes.SET_IS_DARK,
      setChosenTheme: actionTypes.SET_CHOSEN_THEME
    }),
    onClickTheme() {
      this.$vuetify.theme.isDark = !this.$vuetify.theme.isDark
      const isDark = this.$vuetify.theme.isDark
      this.onChangeSelectedTheme(isDark ? THEMES.DARK : THEMES.LIGHT);
      this.setIsDark(isDark)

    }
  },
  computed: {
    ...mapGetters(["languages", "currentLanguage"])
  }
};
</script>
<style lang="scss">
.main {
  .language__btn {
    position: absolute;
    top: 20px;
    right: 20px;
    margin: 0;
    svg {
      width: 32px;
      height: auto;
      display: block;
      path {
        stroke: #3d3d3d;
      }
    }
  }
}
.language {
  &__btn {
    display: block;
    align-items: center;
    justify-content: center;
    margin: 0 12px;
    svg {
      width: 32px;
      height: auto;
      display: block;
    }
  }
  &__list {
    min-width: 100px;
    font-size: 14px;
    &-item {
      display: flex;
      align-items: center;
      min-height: 28px !important;
      cursor: pointer !important;
      transition: all 0.3s ease;
      &.v-list-item.theme--light.active {
        color: rgba(79, 174, 59, 1) !important;
        font-weight: 500;
        background-color: rgba(79, 174, 59, 0.12);
        &:hover {
          background-color: rgba(78, 174, 59, 0.214);
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}
.flag__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-flag {
  margin: -0.9em -1.2em -0.9em -1.2em;
  transform: scale(0.25);
  width: 52px;
  height: 39px;
  border: thin solid #c2c2c2;
}
</style>
