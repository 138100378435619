<template>
  <VDialog
    v-model="visibility"
    max-width="1100px"
    :transition="$dialogTransition"
  >
    <VCard @mousedown="onDialogClick($event)">
      <VRow class="ma-0 align-center justify-space-between">
        <VCol cols="12" md="6">
          <span
            class="font-20 text-uppercase font-weight-bold"
            :class="{ 'cursor-pointer': activeTab === 1 }"
            @click="activeTab = 0"
          >
          {{ language.CREATE_SUPPLY }} на вихід
        </span>
          <span v-if="activeTab === 1">
          <VIcon>mdi-chevron-right</VIcon>
          <span class="font-20 text-uppercase font-weight-regular">ТОВАРИ</span>
        </span>
        </VCol>
      </VRow>
      <VDivider class="mb-3"/>
      <VCardText class="pb-0">
        <VContainer class="py-0">
          <div class="text-center" v-if="loading">
            <VProgressCircular
              :size="50"
              color="primary"
              indeterminate
            ></VProgressCircular>
          </div>
          <div v-else>
<!--            <VTabsItems v-model="activeTab">-->
<!--              <VTabItem>-->
<!--                <VRow class="pa-0 ma-0">-->
<!--                  <VCol cols="12" md="8" class="pa-0 mt-2">-->
<!--                    <VRow>-->
<!--                      <VCol cols="12" md="6" class="ma-0 pa-0">-->
<!--                        <VCol cols="12">-->
<!--                          <VTextField-->
<!--                            v-model="sender.phone"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            class="br-10"-->
<!--                            label="Номер відправника"-->
<!--                            @focusin="sender.phoneMenu = true"-->
<!--                            @input="onInputSenderValue('phone', $event)"-->
<!--                            :hide-details="!senderPhoneErrors.length"-->
<!--                            :error-messages="senderPhoneErrors"-->
<!--                          />-->
<!--                          <div-->
<!--                            class="px-2 position-relative"-->
<!--                            v-if="sender.items.length && sender.phoneMenu"-->
<!--                          >-->
<!--                            <VList-->
<!--                              dense-->
<!--                              class="elevation-5 search-dropdown"-->
<!--                            >-->
<!--                              <VListItem-->
<!--                                class="py-0"-->
<!--                                v-for="(item, index) in sender.items"-->
<!--                                :key="index"-->
<!--                                @click="onChangeSenderAddress('phone', item);-->
<!--                              sender.phoneMenu = false;"-->
<!--                              >-->
<!--                                <VListItemTitle class="search-menu-item">-->
<!--                                  {{ item.name }} {{ item.surname }}-->
<!--                                </VListItemTitle>-->
<!--                              </VListItem>-->
<!--                            </VList>-->
<!--                          </div>-->
<!--                        </VCol>-->
<!--                        <VCol cols="12">-->
<!--                          <VTextField-->
<!--                            v-model="sender.name"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            label="Ім'я відправника"-->
<!--                            class="br-10"-->
<!--                            @focusin="sender.nameMenu = true"-->
<!--                            @input="onInputSenderValue('name', $event)"-->
<!--                            :hide-details="!senderNameErrors.length"-->
<!--                            :error-messages="senderNameErrors"-->
<!--                          />-->
<!--                          <div-->
<!--                            class="px-2 position-relative"-->
<!--                            v-if="sender.items.length && sender.nameMenu"-->
<!--                          >-->
<!--                            <VList-->
<!--                              dense-->
<!--                              class="elevation-5 search-dropdown"-->
<!--                            >-->
<!--                              <VListItem-->
<!--                                class="py-0"-->
<!--                                v-for="(item, index) in sender.items"-->
<!--                                :key="index"-->
<!--                                @click="onChangeSenderAddress('name', item, $event);-->
<!--                              sender.nameMenu = false;"-->
<!--                              >-->
<!--                                <VListItemTitle class="search-menu-item">-->
<!--                                  {{ item.name }} {{ item.surname }}-->
<!--                                </VListItemTitle>-->
<!--                              </VListItem>-->
<!--                            </VList>-->
<!--                          </div>-->
<!--                        </VCol>-->
<!--                        <VCol cols="12">-->
<!--                          <VTextField-->
<!--                            v-model="sender.surname"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            class="br-10"-->
<!--                            label="Прізвище відправника"-->
<!--                            @focusin="sender.surnameMenu = true"-->
<!--                            @input="onInputSenderValue('surname', $event)"-->
<!--                            :hide-details="!senderSurnameErrors.length"-->
<!--                            :error-messages="senderSurnameErrors"-->
<!--                          />-->
<!--                          <div-->
<!--                            class="px-2 position-relative"-->
<!--                            v-if="sender.items.length && sender.surnameMenu"-->
<!--                          >-->
<!--                            <VList-->
<!--                              dense-->
<!--                              class="elevation-5 search-dropdown"-->
<!--                            >-->
<!--                              <VListItem-->
<!--                                class="py-0"-->
<!--                                v-for="(item, index) in sender.items"-->
<!--                                :key="index"-->
<!--                                @click="onChangeSenderAddress('surname', item);-->
<!--                              sender.surnameMenu = false;"-->
<!--                              >-->
<!--                                <VListItemTitle class="search-menu-item">-->
<!--                                  {{ item.name }} {{ item.surname }}-->
<!--                                </VListItemTitle>-->
<!--                              </VListItem>-->
<!--                            </VList>-->
<!--                          </div>-->
<!--                        </VCol>-->
<!--                      </VCol>-->
<!--                      <VCol cols="12" md="6" class="pa-0 ma-0">-->
<!--                        <VCol cols="12">-->
<!--                          <VTextField-->
<!--                            v-model="delivery.phone"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            class="br-10"-->
<!--                            label="Номер отримувача"-->
<!--                            @focusin="delivery.phoneMenu = true"-->
<!--                            @input="onInputDeliveryValue('phone', $event)"-->
<!--                            :hide-details="!deliveryPhoneErrors.length"-->
<!--                            :error-messages="deliveryPhoneErrors"-->
<!--                          />-->
<!--                          <div-->
<!--                            class="px-2 position-relative"-->
<!--                            v-if="delivery.items.length && delivery.phoneMenu"-->
<!--                          >-->
<!--                            <VList-->
<!--                              dense-->
<!--                              class="elevation-5 search-dropdown"-->
<!--                            >-->
<!--                              <VListItem-->
<!--                                class="py-0"-->
<!--                                v-for="(item, index) in delivery.items"-->
<!--                                :key="index"-->
<!--                                @click="onChangeDeliveryAddress('phone', item);-->
<!--                              delivery.phoneMenu = false;"-->
<!--                              >-->
<!--                                <VListItemTitle class="search-menu-item">-->
<!--                                  {{ item.name }} {{ item.surname }}-->
<!--                                </VListItemTitle>-->
<!--                              </VListItem>-->
<!--                            </VList>-->
<!--                          </div>-->
<!--                        </VCol>-->
<!--                        <VCol cols="12">-->
<!--                          <VTextField-->
<!--                            v-model="delivery.name"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            class="br-10"-->
<!--                            label="Ім'я отримувача"-->
<!--                            @focusin="delivery.nameMenu = true"-->
<!--                            @input="onInputDeliveryValue('name', $event)"-->
<!--                            :hide-details="!deliveryNameErrors.length"-->
<!--                            :error-messages="deliveryNameErrors"-->
<!--                          />-->
<!--                          <div-->
<!--                            class="px-2 position-relative"-->
<!--                            v-if="delivery.items.length && delivery.nameMenu"-->
<!--                          >-->
<!--                            <VList-->
<!--                              dense-->
<!--                              class="elevation-5 search-dropdown"-->
<!--                            >-->
<!--                              <VListItem-->
<!--                                class="py-0"-->
<!--                                v-for="(item, index) in delivery.items"-->
<!--                                :key="index"-->
<!--                                @click="onChangeDeliveryAddress('name', item);-->
<!--                              delivery.nameMenu = false;"-->
<!--                              >-->
<!--                                <VListItemTitle class="search-menu-item">-->
<!--                                  {{ item.name }} {{ item.surname }}-->
<!--                                </VListItemTitle>-->
<!--                              </VListItem>-->
<!--                            </VList>-->
<!--                          </div>-->
<!--                        </VCol>-->
<!--                        <VCol cols="12">-->
<!--                          <VTextField-->
<!--                            v-model="delivery.surname"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            class="br-10"-->
<!--                            label="Прізвище отримувача"-->
<!--                            @focusin="delivery.surnameMenu = true"-->
<!--                            @input="onInputDeliveryValue('surname', $event)"-->
<!--                            :hide-details="!deliverySurnameErrors.length"-->
<!--                            :error-messages="deliverySurnameErrors"-->
<!--                          />-->
<!--                          <div-->
<!--                            class="px-2 position-relative"-->
<!--                            v-if="delivery.items.length && delivery.surnameMenu"-->
<!--                          >-->
<!--                            <VList-->
<!--                              dense-->
<!--                              class="elevation-5 search-dropdown"-->
<!--                            >-->
<!--                              <VListItem-->
<!--                                class="py-0"-->
<!--                                v-for="(item, index) in delivery.items"-->
<!--                                :key="index"-->
<!--                                @click="onChangeDeliveryAddress('surname', item);-->
<!--                              delivery.surnameMenu = false;"-->
<!--                              >-->
<!--                                <VListItemTitle class="search-menu-item">-->
<!--                                  {{ item.name }} {{ item.surname }}-->
<!--                                </VListItemTitle>-->
<!--                              </VListItem>-->
<!--                            </VList>-->
<!--                          </div>-->
<!--                        </VCol>-->
<!--                        <VCol cols="12" class="">-->
<!--                          <VAutocomplete-->
<!--                            :items="cities"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            class="br-10"-->
<!--                            label="Область"-->
<!--                            item-text="AreaDescription"-->
<!--                            item-value="AreaRef"-->
<!--                            hide-details-->
<!--                            v-model="delivery.region"-->
<!--                            @change="onSelectRegion"-->
<!--                          />-->
<!--                        </VCol>-->
<!--                        <VCol cols="12">-->
<!--                          <VAutocomplete-->
<!--                            :items="activeCities"-->
<!--                            :loading="regionLoading"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            class="br-10"-->
<!--                            label="Місто отримувача"-->
<!--                            item-text="Description"-->
<!--                            item-value="Ref"-->
<!--                            hide-details-->
<!--                            v-model="delivery.city"-->
<!--                            @change="onSelectCity"-->
<!--                          />-->
<!--                        </VCol>-->
<!--                        <VCol>-->
<!--                          <VAutocomplete-->
<!--                            :items="warehouses"-->
<!--                            outlined-->
<!--                            dense-->
<!--                            class="br-10"-->
<!--                            label="Відділення"-->
<!--                            item-text="Description"-->
<!--                            item-value="Ref"-->
<!--                            v-model="delivery.warehouse"-->
<!--                            hide-details-->
<!--                          />-->
<!--                        </VCol>-->
<!--                      </VCol>-->
<!--                    </VRow>-->
<!--                  </VCol>-->
<!--                  <VCol cols="12" md="4" class="pa-0 mt-2">-->
<!--                    <VCol cols="12" class="pt-0">-->
<!--                      <VTextField-->
<!--                        dense-->
<!--                        outlined-->
<!--                        label="ТТН"-->
<!--                        class="br-10"-->
<!--                        v-model="supplyItem.ttn"-->
<!--                        :error-messages="ttnErrors"-->
<!--                        :hide-details="!ttnErrors.length"-->
<!--                      >-->
<!--                        <template v-slot:append-outer>-->
<!--                          <VIcon-->
<!--                            @click="onSearchByTTN"-->
<!--                            :disabled="!supplyItem.ttn"-->
<!--                          >mdi-magnify</VIcon>-->
<!--                        </template>-->
<!--                      </VTextField>-->
<!--                    </VCol>-->
<!--                    <VCol cols="12">-->
<!--                      <VTextField-->
<!--                        dense-->
<!--                        outlined-->
<!--                        hide-details-->
<!--                        label="Наложний платіж"-->
<!--                        class="br-10"-->
<!--                      />-->
<!--                    </VCol>-->
<!--                    <VCol cols="12">-->
<!--                      <VTextField-->
<!--                        dense-->
<!--                        outlined-->
<!--                        type="number"-->
<!--                        hide-details-->
<!--                        label="Сума"-->
<!--                        v-model="supplyItem.cost"-->
<!--                        class="br-10"-->
<!--                      />-->
<!--                    </VCol>-->
<!--                    <VCol cols="12" class="my-0 py-0">-->
<!--                      <VTextarea-->
<!--                        no-resize-->
<!--                        rows="3"-->
<!--                        color="green"-->
<!--                        class="br-10"-->
<!--                        outlined dense-->
<!--                        placeholder="Додайте коментар"-->
<!--                        v-model="supplyItem.comment"-->
<!--                        required-->
<!--                      ></VTextarea>-->
<!--                    </VCol>-->
<!--                  </VCol>-->
<!--                </VRow>-->
<!--                <VRow class="align-center mt-3 mb-2">-->
<!--                  <VCol cols="12" md="6">-->
<!--                    <VBtn-->
<!--                      outlined-->
<!--                      dense-->
<!--                      class="br-10 text-transform-none px-2"-->
<!--                      style="color: green"-->
<!--                      @click="activeTab = 1"-->
<!--                    >-->
<!--                      <VIcon>mdi-plus</VIcon>-->
<!--                      Додати доступні продукти-->
<!--                    </VBtn>-->
<!--                  </VCol>-->
<!--                  <VCol cols="12" md="6" class="text-right supply-items-counter">-->
<!--                    <div class="counter-absolute">{{ chosenProducts.length }}</div>-->
<!--                    <img :src="require('@/assets/images/supply-counter.png')" alt="">-->
<!--                  </VCol>-->
<!--                </VRow>-->
<!--                <span-->
<!--                  class="text-red text-center d-block"-->
<!--                  v-if="noItemsError && !chosenProducts.length"-->
<!--                >Додайте товари!</span>-->
<!--                <VRow v-if="chosenProducts.length" class="font-weight-bold">-->
<!--                  <VCol cols="1" class="py-0">-->
<!--                  </VCol>-->
<!--                  <VCol cols="4">-->
<!--                    Назва-->
<!--                  </VCol>-->
<!--                  <VCol-->
<!--                    cols="7"-->
<!--                    class="align-center d-flex pa-0"-->
<!--                  >-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      NS Code-->
<!--                    </VCol>-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      Bar Code-->
<!--                    </VCol>-->
<!--                    <VCol cols="2" class="pa-0">-->
<!--                    </VCol>-->
<!--                  </VCol>-->
<!--                </VRow>-->
<!--                <VRow-->
<!--                  class="ma-0 align-center supply-item-preview"-->
<!--                  v-for="chosenProduct in chosenProducts"-->
<!--                  :key="chosenProduct.uuid"-->
<!--                >-->
<!--                  <VCol cols="1" class="py-0">-->
<!--                    <VIcon-->
<!--                      color="red"-->
<!--                      @click="onRemoveItem(chosenProduct.uuid)"-->
<!--                    >mdi-close</VIcon>-->
<!--                  </VCol>-->
<!--                  <VCol cols="4" class="d-flex align-center">-->
<!--                    <span class="d-flex">-->
<!--                      <img-->
<!--                        v-if="!getImagePath(chosenProduct)"-->
<!--                        src="/img/supply-item.png"-->
<!--                        width="80"-->
<!--                        class="br-10"-->
<!--                      >-->
<!--                      <img-->
<!--                        v-else-->
<!--                        :src="getImagePath(chosenProduct)"-->
<!--                        width="80"-->
<!--                        height="52"-->
<!--                        class="br-10"-->
<!--                      >-->
<!--                    </span>-->
<!--                    <span class="d-flex ml-2 font-14 text-black">-->
<!--                      {{ getProductName(chosenProduct.item_details) }}-->
<!--                    </span>-->
<!--                  </VCol>-->
<!--                  <VCol-->
<!--                    cols="7"-->
<!--                    class="align-center d-flex pa-0"-->
<!--                  >-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      {{ chosenProduct.ns_code }}-->
<!--                    </VCol>-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      {{ chosenProduct.bar_code }}-->
<!--                    </VCol>-->
<!--                    <VCol cols="2" class="pa-0">-->
<!--                      <VIcon>mdi-information-outline</VIcon>-->
<!--                    </VCol>-->
<!--                  </VCol>-->
<!--                </VRow>-->
<!--              </VTabItem>-->
<!--              <VTabItem>-->
<!--                <VCol cols="12" md="8">-->
<!--                  <VTextField-->
<!--                    prepend-inner-icon="mdi-magnify"-->
<!--                    dense-->
<!--                    v-model="searchString"-->
<!--                    @input="onSearchProduct"-->
<!--                    clearable-->
<!--                    ref="searchField"-->
<!--                  />-->
<!--                </VCol>-->
<!--                <VRow v-if="products.length" class="font-weight-bold">-->
<!--                  <VCol cols="1" class="py-0">-->
<!--                  </VCol>-->
<!--                  <VCol cols="4">-->
<!--                    Назва-->
<!--                  </VCol>-->
<!--                  <VCol-->
<!--                    cols="7"-->
<!--                    class="align-center d-flex pa-0"-->
<!--                  >-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      NS Code-->
<!--                    </VCol>-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      Bar Code-->
<!--                    </VCol>-->
<!--                    <VCol cols="2" class="pa-0">-->

<!--                    </VCol>-->
<!--                  </VCol>-->
<!--                </VRow>-->
<!--                <VRow-->
<!--                  class="ma-0 align-center supply-item-preview"-->
<!--                  v-for="product in products"-->
<!--                  :key="product.uuid"-->
<!--                >-->
<!--                  <VRow-->
<!--                    v-if="expandedUuid === product.uuid"-->
<!--                    class="align-center mt-1 ml-1"-->
<!--                    @click="expandedUuid = ''"-->
<!--                  >-->
<!--                    <VCol cols="12" md="4" class="pa-0 text-center">-->
<!--                      <img-->
<!--                        v-if="!getImagePath(product)"-->
<!--                        :src="require('@/assets/images/product-desc.png')"-->
<!--                        width="80"-->
<!--                        class="br-10"-->
<!--                        style="width: 140px; height: 90px"-->
<!--                      >-->
<!--                      <img-->
<!--                        v-else-->
<!--                        :src="getImagePath(product)"-->
<!--                        alt=""-->
<!--                        width="80"-->
<!--                        class="br-10"-->
<!--                        style="width: 140px; height: 90px"-->
<!--                      >-->
<!--                    </VCol>-->
<!--                    <VCol cols="12" md="8" class="pa-0 ma-0">-->
<!--                      <span class="font-16 text-black">-->
<!--                        {{ getProductName(product.item_details) }}-->
<!--                      </span>-->
<!--                    </VCol>-->
<!--                    <VCol class="ml-0">-->
<!--                      <p class="text-black pa-0 ma-0">description</p>-->
<!--                      <div class="d-flex align-center">-->
<!--                        <img :src="require('@/assets/images/korobka.png')" alt="">-->
<!--                        <span class="text-black d-flex ml-1">-->
<!--                          {{ getProductWidth(product.item_details) }}-->
<!--                          x-->
<!--                          {{ getProductLength(product.item_details) }}-->
<!--                          x-->
<!--                          {{ getProductHeight(product.item_details) }}-->
<!--                        </span>-->
<!--                      </div>-->
<!--                      <div class="d-flex align-center mt-4">-->
<!--                        <img :src="require('@/assets/images/weight-icon.png')" alt="">-->
<!--                        <span class="text-black d-flex ml-1">-->
<!--                          {{ getProductWeightNet(product.item_details) }} кг.</span>-->
<!--                      </div>-->
<!--                    </VCol>-->
<!--                  </VRow>-->
<!--                  <VCol cols="1" class="py-0">-->
<!--                  </VCol>-->
<!--                  <VCol-->
<!--                    v-if="expandedUuid !== product.uuid"-->
<!--                    cols="4"-->
<!--                    class="align-center d-flex pt-1 pr-0 pl-0 pb-0"-->
<!--                    @click="expandedUuid = product.uuid"-->
<!--                  >-->
<!--                    <span class="d-flex">-->
<!--                      <img-->
<!--                        v-if="!getImagePath(product)"-->
<!--                        src="/img/supply-item.png"-->
<!--                        width="80"-->
<!--                        height="52"-->
<!--                        style="border-radius: 10px"-->
<!--                      >-->
<!--                      <img-->
<!--                        v-else-->
<!--                        :src="getImagePath(product)"-->
<!--                        width="80"-->
<!--                        height="52"-->
<!--                        style="border-radius: 10px"-->
<!--                      >-->
<!--                    </span>-->
<!--                    <span class="d-flex ml-2 font-14 text-black">-->
<!--                      {{ getProductName(product.item_details) }}-->
<!--                    </span>-->
<!--                  </VCol>-->
<!--                  <VCol-->
<!--                    v-if="expandedUuid !== product.uuid"-->
<!--                    cols="7"-->
<!--                    class="pa-0 d-flex align-center justify-space-around"-->
<!--                  >-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      {{ product.ns_code }}-->
<!--                    </VCol>-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      {{ product.bar_code }}-->
<!--                    </VCol>-->
<!--                    <VCol cols="2" class="pa-0 text-right">-->
<!--                      <VIcon-->
<!--                        @click="getItemsByBarCode(product.bar_code)"-->
<!--                      >mdi-send</VIcon>-->
<!--                    </VCol>-->
<!--                  </VCol>-->
<!--                </VRow>-->
<!--              </VTabItem>-->
<!--              <VTabItem class="px-3">-->
<!--                <VBtn depressed text small-->
<!--                      @click="activeTab = 1"-->
<!--                      class="mb-3"-->
<!--                >-->
<!--                  <VIcon>mdi-arrow-left</VIcon> Назад-->
<!--                </VBtn>-->
<!--                <VRow v-if="productsByBarCode.length" class="font-weight-bold">-->
<!--                  <VCol cols="1" class="py-0">-->
<!--                  </VCol>-->
<!--                  <VCol cols="4">-->
<!--                    Назва-->
<!--                  </VCol>-->
<!--                  <VCol-->
<!--                    cols="7"-->
<!--                    class="align-center d-flex pa-0"-->
<!--                  >-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      NS Code-->
<!--                    </VCol>-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      Bar Code-->
<!--                    </VCol>-->
<!--                    <VCol cols="2" class="pa-0">-->

<!--                    </VCol>-->
<!--                  </VCol>-->
<!--                </VRow>-->
<!--                <VRow-->
<!--                  v-for="productByBarCode in productsByBarCode"-->
<!--                  :key="productByBarCode.uuid"-->
<!--                  class="py-3 align-center"-->
<!--                >-->
<!--                  <VCol cols="1" class="py-0">-->
<!--                    <VCheckbox-->
<!--                      :input-value="chosenProductsUuids.includes(productByBarCode.uuid)"-->
<!--                      hide-details-->
<!--                      @change="onChoseByBarCode($event, productByBarCode)"-->
<!--                    />-->
<!--                  </VCol>-->
<!--                  <VCol-->
<!--                    cols="4"-->
<!--                    class="align-center d-flex pt-1 pr-0 pl-0 pb-0"-->
<!--                  >-->
<!--                    <span class="d-flex">-->
<!--                      <img-->
<!--                        v-if="!getImagePath(productByBarCode)"-->
<!--                        src="/img/supply-item.png"-->
<!--                        width="80"-->
<!--                        height="52"-->
<!--                        style="border-radius: 10px"-->
<!--                      >-->
<!--                      <img-->
<!--                        v-else-->
<!--                        :src="getImagePath(productByBarCode)"-->
<!--                        width="80"-->
<!--                        height="52"-->
<!--                        style="border-radius: 10px"-->
<!--                      >-->
<!--                    </span>-->
<!--                    <span class="d-flex ml-2 font-14 text-black">-->
<!--                      {{ getProductName(productByBarCode.item_details) }}-->
<!--                    </span>-->
<!--                  </VCol>-->
<!--                  <VCol-->
<!--                    cols="7"-->
<!--                    class="pa-0 d-flex align-center justify-space-around"-->
<!--                  >-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      {{ productByBarCode.ns_code }}-->
<!--                    </VCol>-->
<!--                    <VCol cols="5" class="pa-0">-->
<!--                      {{ productByBarCode.bar_code }}-->
<!--                    </VCol>-->
<!--                    <VCol cols="2" class="pa-0 text-right">-->
<!--                    </VCol>-->
<!--                  </VCol>-->
<!--                </VRow>-->
<!--              </VTabItem>-->
<!--            </VTabsItems>-->
            <VTabsItems v-model="activeTab">
              <VTabItem>
                <VRow class="pa-0 ma-0">
                  <VCol cols="12" md="8" class="pa-0 mt-2">
                    <VRow>
                      <VCol cols="12" md="6" class="ma-0 pa-0">
                        <VCol cols="12">
                          <VTextField
                            v-model="sender.phone"
                            outlined
                            dense
                            class="br-10"
                            label="Номер відправника"
                            @focusin="sender.phoneMenu = true"
                            @input="onInputSenderValue('phone', $event)"
                            :hide-details="!senderPhoneErrors.length"
                            :error-messages="senderPhoneErrors"
                          />
                          <div
                            class="px-2 position-relative"
                            v-if="sender.items.length && sender.phoneMenu"
                          >
                            <VList
                              dense
                              class="elevation-5 search-dropdown"
                            >
                              <VListItem
                                class="py-0"
                                v-for="(item, index) in sender.items"
                                :key="index"
                                @click="onChangeSenderAddress('phone', item);
                              sender.phoneMenu = false;"
                              >
                                <VListItemTitle class="search-menu-item">
                                  {{ item.name }} {{ item.surname }}
                                </VListItemTitle>
                              </VListItem>
                            </VList>
                          </div>
                        </VCol>
                        <VCol cols="12">
                          <VTextField
                            v-model="sender.name"
                            outlined
                            dense
                            label="Ім'я відправника"
                            class="br-10"
                            @focusin="sender.nameMenu = true"
                            @input="onInputSenderValue('name', $event)"
                            :hide-details="!senderNameErrors.length"
                            :error-messages="senderNameErrors"
                          />
                          <div
                            class="px-2 position-relative"
                            v-if="sender.items.length && sender.nameMenu"
                          >
                            <VList
                              dense
                              class="elevation-5 search-dropdown"
                            >
                              <VListItem
                                class="py-0"
                                v-for="(item, index) in sender.items"
                                :key="index"
                                @click="onChangeSenderAddress('name', item, $event);
                              sender.nameMenu = false;"
                              >
                                <VListItemTitle class="search-menu-item">
                                  {{ item.name }} {{ item.surname }}
                                </VListItemTitle>
                              </VListItem>
                            </VList>
                          </div>
                        </VCol>
                        <VCol cols="12">
                          <VTextField
                            v-model="sender.surname"
                            outlined
                            dense
                            class="br-10"
                            label="Прізвище відправника"
                            @focusin="sender.surnameMenu = true"
                            @input="onInputSenderValue('surname', $event)"
                            :hide-details="!senderSurnameErrors.length"
                            :error-messages="senderSurnameErrors"
                          />
                          <div
                            class="px-2 position-relative"
                            v-if="sender.items.length && sender.surnameMenu"
                          >
                            <VList
                              dense
                              class="elevation-5 search-dropdown"
                            >
                              <VListItem
                                class="py-0"
                                v-for="(item, index) in sender.items"
                                :key="index"
                                @click="onChangeSenderAddress('surname', item);
                              sender.surnameMenu = false;"
                              >
                                <VListItemTitle class="search-menu-item">
                                  {{ item.name }} {{ item.surname }}
                                </VListItemTitle>
                              </VListItem>
                            </VList>
                          </div>
                        </VCol>
                      </VCol>
                      <VCol cols="12" md="6" class="pa-0 ma-0">
                        <VCol cols="12">
                          <VTextField
                            v-model="delivery.phone"
                            outlined
                            dense
                            class="br-10"
                            label="Номер отримувача"
                            @focusin="delivery.phoneMenu = true"
                            @input="onInputDeliveryValue('phone', $event)"
                            :hide-details="!deliveryPhoneErrors.length"
                            :error-messages="deliveryPhoneErrors"
                          />
                          <div
                            class="px-2 position-relative"
                            v-if="delivery.items.length && delivery.phoneMenu"
                          >
                            <VList
                              dense
                              class="elevation-5 search-dropdown"
                            >
                              <VListItem
                                class="py-0"
                                v-for="(item, index) in delivery.items"
                                :key="index"
                                @click="onChangeDeliveryAddress('phone', item);
                              delivery.phoneMenu = false;"
                              >
                                <VListItemTitle class="search-menu-item">
                                  {{ item.name }} {{ item.surname }}
                                </VListItemTitle>
                              </VListItem>
                            </VList>
                          </div>
                        </VCol>
                        <VCol cols="12">
                          <VTextField
                            v-model="delivery.name"
                            outlined
                            dense
                            class="br-10"
                            label="Ім'я отримувача"
                            @focusin="delivery.nameMenu = true"
                            @input="onInputDeliveryValue('name', $event)"
                            :hide-details="!deliveryNameErrors.length"
                            :error-messages="deliveryNameErrors"
                          />
                          <div
                            class="px-2 position-relative"
                            v-if="delivery.items.length && delivery.nameMenu"
                          >
                            <VList
                              dense
                              class="elevation-5 search-dropdown"
                            >
                              <VListItem
                                class="py-0"
                                v-for="(item, index) in delivery.items"
                                :key="index"
                                @click="onChangeDeliveryAddress('name', item);
                              delivery.nameMenu = false;"
                              >
                                <VListItemTitle class="search-menu-item">
                                  {{ item.name }} {{ item.surname }}
                                </VListItemTitle>
                              </VListItem>
                            </VList>
                          </div>
                        </VCol>
                        <VCol cols="12">
                          <VTextField
                            v-model="delivery.surname"
                            outlined
                            dense
                            class="br-10"
                            label="Прізвище отримувача"
                            @focusin="delivery.surnameMenu = true"
                            @input="onInputDeliveryValue('surname', $event)"
                            :hide-details="!deliverySurnameErrors.length"
                            :error-messages="deliverySurnameErrors"
                          />
                          <div
                            class="px-2 position-relative"
                            v-if="delivery.items.length && delivery.surnameMenu"
                          >
                            <VList
                              dense
                              class="elevation-5 search-dropdown"
                            >
                              <VListItem
                                class="py-0"
                                v-for="(item, index) in delivery.items"
                                :key="index"
                                @click="onChangeDeliveryAddress('surname', item);
                              delivery.surnameMenu = false;"
                              >
                                <VListItemTitle class="search-menu-item">
                                  {{ item.name }} {{ item.surname }}
                                </VListItemTitle>
                              </VListItem>
                            </VList>
                          </div>
                        </VCol>
                        <VCol cols="12" class="">
                          <VAutocomplete
                            :items="cities"
                            outlined
                            dense
                            class="br-10"
                            label="Область"
                            item-text="AreaDescription"
                            item-value="AreaRef"
                            hide-details
                            v-model="delivery.region"
                            @change="onSelectRegion"
                          />
                        </VCol>
                        <VCol cols="12">
                          <VAutocomplete
                            :items="activeCities"
                            :loading="regionLoading"
                            outlined
                            dense
                            class="br-10"
                            label="Місто отримувача"
                            item-text="Description"
                            item-value="Ref"
                            hide-details
                            @change="onSelectCity"
                            v-model="delivery.city"
                          />
                        </VCol>
                        <VCol>
                          <VAutocomplete
                            :items="warehouses"
                            outlined
                            dense
                            class="br-10"
                            label="Відділення"
                            item-text="Description"
                            item-value="Ref"
                            hide-details
                            v-model="delivery.warehouse"
                          />
                        </VCol>
                      </VCol>
                    </VRow>
                  </VCol>
                  <VCol cols="12" md="4" class="pa-0 mt-2">
                    <VCol cols="12" class="pt-0">
                      <VTextField
                        dense
                        outlined
                        label="ТТН"
                        class="br-10"
                        v-model="supplyItem.ttn"
                        :error-messages="ttnErrors"
                        :hide-details="!ttnErrors.length"
                      >
                        <template v-slot:append-outer>
                          <VIcon
                            @click="onSearchByTTN"
                            :disabled="!supplyItem.ttn"
                          >mdi-magnify</VIcon>
                        </template>
                      </VTextField>
                    </VCol>
                    <VCol cols="12">
                      <VTextField
                        dense
                        outlined
                        hide-details
                        label="Наложний платіж"
                        class="br-10"
                      />
                    </VCol>
                    <VCol cols="12">
                      <VTextField
                        dense
                        outlined
                        type="number"
                        hide-details
                        label="Сума"
                        v-model="supplyItem.cost"
                        class="br-10"
                      />
                    </VCol>
                    <VCol cols="12" class="my-0 py-0">
                      <VTextarea
                        no-resize
                        rows="3"
                        color="green"
                        class="br-10"
                        outlined dense
                        placeholder="Додайте коментар"
                        v-model="supplyItem.comment"
                        required
                      ></VTextarea>
                    </VCol>
                  </VCol>
                </VRow>
                <VRow class="align-center mt-3 mb-2">
                  <VCol cols="12" md="6">
                    <VBtn
                      outlined
                      dense
                      class="br-10 text-transform-none px-2"
                      style="color: green"
                      @click="activeTab = 1"
                    >
                      <VIcon>mdi-plus</VIcon>
                      Додати доступні продукти
                    </VBtn>
                  </VCol>
                  <VCol cols="12" md="6" class="text-right supply-items-counter">
                    <div class="counter-absolute">{{ chosenProducts.length }}</div>
                    <img :src="require('@/assets/images/supply-counter.png')" alt="">
                  </VCol>
                </VRow>
                <span
                  class="text-red text-center d-block"
                  v-if="noItemsError && !chosenProducts.length"
                >Додайте товари!</span>
                <VRow v-if="chosenProducts.length" class="font-weight-bold">
                  <VCol cols="1" class="py-0">
                  </VCol>
                  <VCol cols="4">
                    Назва
                  </VCol>
                  <VCol
                    cols="7"
                    class="align-center d-flex pa-0"
                  >
                    <VCol cols="8" class="pa-0">
                      Bar Code
                    </VCol>
                    <VCol cols="2" class="pa-0">
                      Кількість
                    </VCol>
                    <VCol cols="2" class="pa-0">

                    </VCol>
                  </VCol>
                </VRow>
                <VRow
                  class="ma-0 align-center supply-item-preview"
                  v-for="chosenProduct in chosenProducts"
                  :key="chosenProduct.uuid"
                >
                  <VCol cols="1" class="py-0">
                    <VIcon
                      color="red"
                      @click="onRemoveItem(chosenProduct.uuid)"
                    >mdi-close</VIcon>
                  </VCol>
                  <VCol cols="4" class="d-flex align-center">
                    <span class="d-flex">
                      <img
                        v-if="!chosenProduct.product_details.path_img"
                        src="/img/supply-item.png"
                        width="80"
                        class="br-10"
                      >
                      <img
                        v-else
                        :src="chosenProduct.product_details.path_img"
                        width="80"
                        height="52"
                        class="br-10"
                      >
                    </span>
                    <span class="d-flex ml-2 font-14 text-black">
                      {{ getProductName(chosenProduct.product_details) }}
                    </span>
                  </VCol>
                  <VCol
                    cols="7"
                    class="align-center d-flex pa-0"
                  >
                    <VCol cols="8" class="pa-0">
                      {{ chosenProduct.product_details.bar_code }}
                    </VCol>
                    <VCol cols="2" class="pa-0 text-center">
                      {{ chosenProduct.count }}
                    </VCol>
                    <VCol cols="2" class="pa-0 text-right">
                      <VIcon>mdi-information-outline</VIcon>
                    </VCol>
                  </VCol>
                </VRow>
              </VTabItem>
              <VTabItem>
                <VCol cols="12" md="8">
                  <VTextField
                    prepend-inner-icon="mdi-magnify"
                    dense
                    v-model="searchString"
                    @input="onSearchProduct"
                    clearable
                    ref="searchField"
                  />
                </VCol>
                <VRow v-if="products.length" class="font-weight-bold">
                  <VCol cols="1" class="py-0">
                  </VCol>
                  <VCol cols="4">
                    Назва
                  </VCol>
                  <VCol
                    cols="7"
                    class="align-center d-flex pa-0"
                  >
                    <VCol cols="8" class="pa-0">
                      Bar Code
                    </VCol>
                    <VCol cols="2" class="pa-0">
                      Кількість
                    </VCol>
                    <VCol cols="2" class="pa-0">
                    </VCol>
                  </VCol>
                </VRow>
                <VRow
                  class="ma-0 align-center supply-item-preview cursor-pointer"
                  v-for="product in products"
                  :key="product.uuid"
                >
                  <VCol cols="1" class="py-0" @click="getItemsByBarCode(product.bar_code)">
                    <VTooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <VIcon
                          v-bind="attrs"
                          v-on="on"
                        >mdi-format-list-bulleted</VIcon>
                      </template>
                      <span>Обрати продукти</span>
                    </VTooltip>
                  </VCol>
                  <VCol
                    cols="4"
                    class="align-center d-flex pt-1 pr-0 pl-0 pb-0"
                  >
                    <span class="d-flex">
                      <img
                        v-if="!product.product_details.path_img"
                        src="/img/supply-item.png"
                        width="80"
                        height="52"
                        style="border-radius: 10px"
                      >
                      <img
                        v-else
                        :src="product.product_details.path_img"
                        width="80"
                        height="52"
                        style="border-radius: 10px"
                      >
                    </span>
                    <span class="d-flex ml-2 font-14 text-black">
                      {{ getProductName(product.product_details) }}
                    </span>
                  </VCol>
                  <VCol
                    cols="7"
                    class="pa-0 d-flex align-center justify-space-around"
                  >
                    <VCol cols="8" class="pa-0">
                      {{ product.product_details.bar_code }}
                    </VCol>
                    <VCol cols="2" class="pa-0">
                      <VTextField
                        type="number"
                        dense
                        outlined
                        class="br-10"
                        hide-details
                        :disabled="product.count < 1"
                        v-model="product.count"
                      />
                    </VCol>
                    <VCol cols="2" class="pa-0 text-right">
                      <VIcon
                        @click="onChoseByBarCode($event, product, 'by_bar_code')"
                        :color="chosenProductsUuids.includes(product.uuid) ? 'green' : ''"
                      >mdi-send</VIcon>
                    </VCol>
                  </VCol>
                </VRow>
              </VTabItem>
              <VTabItem class="px-3">
                <VBtn depressed text small
                      @click="activeTab = 1"
                      class="mb-3"
                >
                  <VIcon>mdi-arrow-left</VIcon> Назад
                </VBtn>
                <VRow v-if="productsByBarCode.length" class="font-weight-bold">
                  <VCol cols="1" class="py-0">
                  </VCol>
                  <VCol cols="4">
                    Назва
                  </VCol>
                  <VCol
                    cols="7"
                    class="align-center d-flex pa-0"
                  >
                    <VCol cols="8" class="pa-0">
                      Bar Code
                    </VCol>
                    <VCol cols="2" class="pa-0">
                    </VCol>
                  </VCol>
                </VRow>
                <VRow
                  v-for="productByBarCode in productsByBarCode"
                  :key="productByBarCode.uuid"
                  class="py-3 align-center"
                >
                  <VCol cols="1" class="py-0">
                    <VCheckbox
                      :value="chosenProductsUuids.includes(productByBarCode.uuid)"
                      hide-details
                      @change="onChoseByBarCode($event, productByBarCode)"
                    />
                  </VCol>
                  <VCol
                    cols="4"
                    class="align-center d-flex pt-1 pr-0 pl-0 pb-0"
                  >
                    <span class="d-flex">
                      <img
                        v-if="!productByBarCode.product_details.path_img"
                        src="/img/supply-item.png"
                        width="80"
                        height="52"
                        style="border-radius: 10px"
                      >
                      <img
                        v-else
                        :src="productByBarCode.product_details.path_img"
                        width="80"
                        height="52"
                        style="border-radius: 10px"
                      >
                    </span>
                    <span class="d-flex ml-2 font-14 text-black">
                      {{ getProductName(productByBarCode.product_details) }}
                    </span>
                  </VCol>
                  <VCol
                    cols="7"
                    class="pa-0 d-flex align-center justify-space-around"
                  >
                    <VCol cols="8" class="pa-0">
                      {{ productByBarCode.product_details.bar_code }}
                    </VCol>
                    <VCol cols="2" class="pa-0 text-right">
                    </VCol>
                  </VCol>
                </VRow>
              </VTabItem>
            </VTabsItems>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="12" md="4" sm="12" class="my-0 py-0">
              <VBtn
                v-if="activeTab === 0"
                @click="visibility = false"
                block
                class="w-100 br-10 text-transform-none"
                dark
                :disabled="loading"
              >
                {{ language.CANCEL }}
              </VBtn>
              <VBtn
                v-else
                @click="activeTab = 0"
                block
                class="w-100 br-10 text-transform-none"
                dark
              >
                Назад
              </VBtn>
            </VCol>
            <VCol cols="12" md="4" sm="12" class="my-0 py-0">
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                block
                v-if="activeTab === 0"
                @click="onCreate('save')"
                :loading="loading"
              >
                {{ language.CREATE }}
              </VBtn>
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                block
                v-else
                @click="activeTab = 0"
              >
                Обрати
              </VBtn>
            </VCol>
            <VCol
              cols="12"
              md="4"
              sm="12"
              class="my-0 py-0"
              v-if="activeTab === 0"
            >
              <VBtn
                class="text-white text-transform-none br-10 w-100"
                :class="{ 'success-bg': !sendButtonDisabled }"
                block
                @click="onCreate('send')"
                :disabled="sendButtonDisabled"
              >
                Відправити
                <VIcon small class="ml-2">mdi-send</VIcon>
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import language from '../../../mixins/language';
import notifications from '../../../mixins/notifications';
import depotService from '../../../services/request/depot/depotService';
import supplyCart from '../../../mixins/supplyCart';
import novaPochta from '../../../mixins/novaPochta';
import supplyOutDialogMixin from '../supplyOutDialogMixin';
import novaPochtaService from '../../../services/request/novaPochta/novaPochtaService';

export default {
  mixins: [
    language, validationMixin,
    notifications,
    supplyCart, novaPochta,
    supplyOutDialogMixin,
  ],
  name: 'AddSupplyOutCart',
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    defaultItem: {
      required: false,
      default: null,
    },
  },
  validations: {
    sender: {
      name: { required },
      surname: { required },
      phone: { required },
    },
    delivery: {
      name: { required },
      surname: { required },
      phone: { required },
    },
  },
  data: () => ({
    loading: false,
    adding: false,
    newItem: {
      uuid: '',
      amount: 1,
    },
    delivery: {
      nameMenu: false,
      surnameMenu: false,
      phoneMenu: false,
      cityMenu: false,
      name: '',
      surname: '',
      phone: '',
      city: '',
      email: '',
      items: [],
      region: '',
      nameValue: '',
      surnameValue: '',
      phoneValue: '',
      cityValue: '',
      uuidValue: '',
      uuid: '',
      warehouse: '',
    },
    deliveryAddress: {
      uuid: '',
    },
    sender: {
      nameMenu: false,
      surnameMenu: false,
      phoneMenu: false,
      name: '',
      surname: '',
      phone: '',
      email: '',
      items: [],
      nameValue: '',
      surnameValue: '',
      phoneValue: '',
      uuidValue: '',
      uuid: '',
    },
    senderAddress: {
      uuid: '',
    },
    supplyItem: {
      ttn: '',
      cost: '',
      comment: '',
      id_state: 400,
    },
    supplyIsChanged: false,
    supplyItems: [],
    activeTab: 0,
    searchString: '',
    products: [],
    chosenProducts: [],
    noItemsError: false,
    expandedUuid: '',
    addresses: [],
    states: [],
    productsByBarCode: [],
  }),
  watch: {
    sender: {
      deep: true,
      handler() {
        if (
          this.sender.name.trim() === this.senderAddress.name?.trim()
          && this.sender.surname.trim() === this.senderAddress.surname?.trim()
          && this.sender.phone.trim() === this.senderAddress.phone?.trim()
        ) {
          this.sender.uuid = this.senderAddress.uuid.trim();
        } else {
          this.sender.uuid = '';
        }
      },
    },
    delivery: {
      deep: true,
      handler() {
        if (
          this.delivery.name.trim() === this.deliveryAddress.name?.trim()
          && this.delivery.surname.trim() === this.deliveryAddress.surname?.trim()
          && this.delivery.phone.trim() === this.deliveryAddress.phone?.trim()
          && this.delivery.city.trim() === this.deliveryAddress.city?.trim()
        ) {
          this.delivery.uuid = this.deliveryAddress.uuid.trim();
        } else {
          this.delivery.uuid = '';
        }
      },
    },
    supplyItem: {
      deep: true,
      handler() {
        const values = Object.values(this.supplyItem);
        this.supplyIsChanged = values.filter((item) => item).length;
      },
    },
    defaultItem() {
      if (this.defaultItem) {
        let itemExists = false;
        this.defaultItem.items_amount = 1;
        this.chosenProducts.forEach((item) => {
          if (item.uuid === this.defaultItem.uuid) {
            itemExists = true;
          }
        });
        if (!itemExists) {
          this.chosenProducts.push(this.defaultItem);
        }
      }
    },
  },
  methods: {
    async onSearchByTTN() {
      try {
        const params = {
          ttn: this.supplyItem.ttn,
        };
        if (this.sender.phone) {
          params.phone = this.sender.phone;
        }
        if (this.delivery.phone) {
          params.phone = this.delivery.phone;
        }
        const documents = await novaPochtaService.searchByTTN(params);
        if (documents.length) {
          const document = documents[0];
          this.delivery.region = '';
          this.delivery.city = document?.RefCityRecipient;
          if (document.SenderFullNameEW) {
            // eslint-disable-next-line prefer-destructuring
            this.sender.surname = document.SenderFullNameEW.split(' ')[0];
            // eslint-disable-next-line prefer-destructuring
            this.sender.name = document.SenderFullNameEW.split(' ')[1];
          }
          if (document.RecipientFullName) {
            // eslint-disable-next-line prefer-destructuring
            this.delivery.surname = document.RecipientFullName.split(' ')[0];
            // eslint-disable-next-line prefer-destructuring
            this.delivery.name = document.RecipientFullName.split(' ')[1];
          }
          if (document.PhoneSender) {
            this.sender.phone = `+${document.PhoneSender}`;
          }
          if (this.delivery.city) {
            await this.onSelectCity(this.delivery.city);
          }
          const city = this.cities.find((cityItem) => cityItem.Ref === this.delivery.city);
          this.delivery.region = city?.AreaRef;
          this.delivery.warehouse = document?.WarehouseRecipientInternetAddressRef;
          this.supplyItem.cost = document?.DocumentCost;
        }
      } catch (e) {
        console.log(e)
      }
    },
    onRemoveItem(uuid) {
      this.chosenProducts = this.chosenProducts.filter((item) => item.uuid !== uuid);
      this.deleteProductCart(uuid);
    },
    onChoseByBarCode(checked, product, type) {
      if (type === 'by_bar_code') {
        // eslint-disable-next-line no-param-reassign
        product = {
          ...product,
          type: 'by_bar_code',
        };
      }
      if (product.type === 'by_bar_code') {
        if (this.chosenProductsUuids.includes(product.uuid)) {
          this.chosenProducts = this.chosenProducts.filter((item) => item.uuid !== product.uuid);
        } else {
          this.chosenProducts.push(product);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (checked) {
          if (product) {
            // eslint-disable-next-line no-param-reassign
            product.count = 1;
            this.chosenProducts.push(product);
          }
        } else {
          this.chosenProducts = this.chosenProducts.filter((item) => item.uuid !== product.uuid);
        }
      }
    },
    onChoseProduct(checked, productUuid) {
      if (checked) {
        const product = this.products.find((item) => item.uuid === productUuid);
        if (product) {
          const productData = {
            ...product,
          };
          if (product.items_amount > 1) {
            productData.items_amount = 1;
          }
          this.chosenProducts.push(productData);
        }
      } else {
        this.chosenProducts = this.chosenProducts.filter((item) => item.uuid !== productUuid);
      }
    },
    async onSearchProduct() {
      if (this.searchString) {
        await this.getProducts({
          product_details__name: this.searchString,
        });
      } else {
        await this.getProducts();
      }
      this.$refs.searchField.focus();
    },
    onDeleteSupplyItem(index) {
      this.supplyItems = this.supplyItems.filter((value, elIndex) => elIndex !== index);
      this.clearProperty('newItem');
    },
    getProductNameByUuid(uuid) {
      return this.products.find((item) => item.uuid === uuid)?.product_details?.name;
    },
    onCancelAdd() {
      this.adding = false;
      this.clearProperty('newItem');
    },
    onAddItem() {
      this.supplyItems.push({
        uuid: this.newItem.uuid,
        amount: this.newItem.amount,
      });
      this.clearProperty('newItem');
      this.adding = false;
    },
    async getProducts(params = {}) {
      try {
        this.products = await depotService.getMyProducts({
          groupby: 'product_details.bar_code',
          // id_state: 810,
          ...params,
        });
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getProducts();
      this.chosenProducts = this.supplyProducts;
      await this.fetchNovaPochtaCities();
      this.activeCities = this.cities;
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
  computed: {
    senderNameErrors() {
      const errors = [];
      if (!this.$v.sender.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.sender.name.required
      && errors.push(this.language.isRequired(this.language.NAME));
      return errors;
    },
    senderSurnameErrors() {
      const errors = [];
      if (!this.$v.sender.surname.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.sender.surname.required
      && errors.push(this.language.isRequired(this.language.SURNAME));
      return errors;
    },
    senderPhoneErrors() {
      const errors = [];
      if (!this.$v.sender.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.sender.phone.required
      && errors.push(this.language.isRequired(this.language.PHONE));
      return errors;
    },
    deliveryNameErrors() {
      const errors = [];
      if (!this.$v.delivery.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.name.required
      && errors.push(this.language.isRequired(this.language.NAME));
      return errors;
    },
    deliverySurnameErrors() {
      const errors = [];
      if (!this.$v.delivery.surname.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.surname.required
      && errors.push(this.language.isRequired(this.language.SURNAME));
      return errors;
    },
    deliveryPhoneErrors() {
      const errors = [];
      if (!this.$v.delivery.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.phone.required
      && errors.push(this.language.isRequired(this.language.PHONE));
      return errors;
    },
    deliveryCityErrors() {
      const errors = [];
      // if (!this.$v.delivery.city.$dirty) {
      //   return errors;
      // }
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.delivery.city.required
      // && errors.push(this.language.isRequired(this.language.CITY));
      return errors;
    },
    chosenProductsUuids() {
      return this.chosenProducts.map((item) => item.uuid);
    },
    availableItems: {
      get() {
        const unavailableUuids = this.supplyItems.map((item) => item.uuid);
        return this.products.filter((product) => !unavailableUuids.includes(product.uuid));
      },
      set() {},
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    senderAddressErrors() {
      const errors = [];
      // if (!this.$v.supplyItem.senderAddress.uuid.$dirty) {
      //   return errors;
      // }
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.supplyItem.senderAddress.uuid.required
      // && errors.push(this.language.isRequired(this.language.ADDRESS));
      return errors;
    },
    deliveryAddressErrors() {
      const errors = [];
      // if (!this.$v.supplyItem.deliveryAddress.uuid.$dirty) {
      //   return errors;
      // }
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.supplyItem.deliveryAddress.uuid.required
      // && errors.push(this.language.isRequired(this.language.ADDRESS));
      return errors;
    },
    ttnErrors() {
      const errors = [];
      // if (!this.$v.supplyItem.ttn.$dirty) {
      //   return errors;
      // }
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.supplyItem.ttn.required
      // && errors.push(this.language.isRequired('ТТН'));
      return errors;
    },
  },
};
</script>

<style scoped lang="scss">
.search-dropdown {
  position:absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 999;
  right: 0;
}
.v-input--selection-controls {
  margin-top: 0;
}
.supply-items-counter {
  position: relative;
  img {
    position: absolute;
    right: 15px;
    bottom: -4px;
  }
  .counter-absolute {
    position: absolute;
    right: 17px;
    bottom: 22px;
    width: 16px;
    z-index: 1;
    height: 16px;
    text-align: center;
    font-size: 13px;
    border-radius: 50%;
    background: #F8F8F8;
    box-shadow: 0px 0px 2px rgb(0 0 0);
  }
}
.supply-item-preview {
  padding: 0;
  &:not(:last-child) {
    border-bottom: 2px solid #E2E2E2;
    padding-bottom: 4px;
  }
  &:last-child {
    padding-top: 4px;
  }
}
</style>
