import * as mutationTypes from "./types/mutations";
import { DEFAULT_STORAGE } from "@/mixins/constants";

export default {
  [mutationTypes.SET_CRM_ITEMS_CHECK]: (state, check) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("ns-data") !== null) {
      data = JSON.parse(localStorage.getItem("ns-data"));
    }

    data.settings.items_check__crm = check;
    if ("settings" in data) {
      data.settings.items_check__crm = check;
    } else {
      data.settings = {
        items_check__crm: check || false,
      };
    }
    localStorage.setItem("ns-data", JSON.stringify(data));

    state.crmItemsCheck = check;
  },
  [mutationTypes.SET_DELIVERIES_ITEMS_CHECK]: (state, check) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("ns-data") !== null) {
      data = JSON.parse(localStorage.getItem("ns-data"));
    }
    if ("settings" in data) {
      data.settings.items_check__supply = check;
    } else {
      data.settings = {
        items_check__supply: check || false,
      };
    }

    localStorage.setItem("ns-data", JSON.stringify(data));

    state.deliveriesItemsCheck = check;
  },
};
