import moment from 'moment';
import addressService from '../../services/request/address/addressService';
import supplyService from '../../services/request/supply/supplyService';
import EventBus from '../../events/EventBus';
import depotService from '../../services/request/depot/depotService';
import notifications from '../../mixins/notifications';
import user from '../../mixins/user';

export default {
  mixins: [
    notifications,
    user,
  ],
  methods: {
    onDialogClick(e) {
      const classes = e.target.className.split(' ');
      if (!classes.includes('search-menu-item') && !classes.includes('search-dropdown')) {
        Object.keys(this.sender).forEach((key) => {
          if (key.includes('Menu')) {
            this.sender[key] = false;
          }
        });
        Object.keys(this.delivery).forEach((key) => {
          if (key.includes('Menu')) {
            this.delivery[key] = false;
          }
        });
      }
    },
    async onInputSenderValue(property) {
      try {
        if (property === 'name') {
          if (this.sender.name) {
            const response = await addressService.getAddressesList({
              name: this.sender.name,
              uselike: true,
            });
            this.sender = {
              ...this.sender,
              items: response,
            };
            if (this.sender.items) {
              this.sender.nameMenu = true;
            }
            if (this.sender.name !== this.senderAddress.name) {
              this.sender.uuid = '';
            } else {
              this.sender.uuid = this.senderAddress.uuid;
            }
          }
        }
        if (property === 'surname') {
          if (this.sender.surname) {
            this.sender.items = await addressService.getAddressesList({
              surname: this.sender.surname,
              uselike: true,
            });
            if (this.sender.items) {
              this.sender.surnameMenu = true;
            }
            if (this.sender.surname !== this.senderAddress.surname) {
              this.sender.uuid = '';
            } else {
              this.sender.uuid = this.senderAddress.uuid;
            }
          }
        }
        if (property === 'phone') {
          if (this.sender.phone) {
            this.sender.items = await addressService.getAddressesList({
              phone: this.sender.phone,
              uselike: true,
            });
            if (this.sender.items) {
              this.sender.phoneMenu = true;
            }
            if (this.sender.phone !== this.senderAddress.phone) {
              this.sender.uuid = '';
            } else {
              this.sender.uuid = this.senderAddress.uuid;
            }
          }
        }
      } catch (e) {
        // console.log(e)
        // orderDialog(e);
      }
    },
    onChangeSenderAddress(property, addressObject) {
      if (typeof addressObject === 'object') {
        this.senderAddress = {
          ...addressObject,
        };
        if (this.senderAddress.name) {
          this.sender.name = this.senderAddress.name;
        }
        if (this.senderAddress.surname) {
          this.sender.surname = this.senderAddress.surname;
        }
        if (this.senderAddress.phone) {
          this.sender.phone = this.senderAddress.phone;
        }
        this.sender.email = this.senderAddress.email;
        if (this.senderAddress.uuid) {
          this.sender.uuid = this.senderAddress.uuid;
        }
      } else if (typeof addressObject === 'string') {
        if (property === 'name') {
          this.sender.name = addressObject;
        }
        if (property === 'surname') {
          this.sender.surname = addressObject;
        }
        if (property === 'phone') {
          this.sender.phone = addressObject;
        }
        this.sender.uuid = '';
      }
    },
    async onInputDeliveryValue(property) {
      try {
        if (property === 'name') {
          if (this.delivery.name) {
            this.delivery.items = await addressService.getAddressesList({
              name: this.delivery.name,
              uselike: true,
            });
            if (this.delivery.items) {
              this.delivery.nameMenu = true;
            }
            if (this.delivery.name !== this.deliveryAddress.name) {
              this.delivery.uuid = '';
            } else {
              this.delivery.uuid = this.deliveryAddress.uuid;
            }
          }
        }
        if (property === 'surname') {
          if (this.delivery.surname) {
            this.delivery.items = await addressService.getAddressesList({
              surname: this.delivery.surname,
              uselike: true,
            });
            if (this.delivery.items) {
              this.delivery.surnameMenu = true;
            }
            if (this.delivery.surname !== this.deliveryAddress.surname) {
              this.delivery.uuid = '';
            } else {
              this.delivery.uuid = this.deliveryAddress.uuid;
            }
          }
        }
        if (property === 'phone') {
          if (this.delivery.phone) {
            this.delivery.items = await addressService.getAddressesList({
              phone: this.delivery.phone,
              uselike: true,
            });
            if (this.delivery.items) {
              this.delivery.phoneMenu = true;
            }
            if (this.delivery.phone !== this.deliveryAddress.phone) {
              this.delivery.uuid = '';
            } else {
              this.delivery.uuid = this.deliveryAddress.uuid;
            }
          }
        }
        if (property === 'city') {
          if (this.delivery.city) {
            this.delivery.items = await addressService.getAddressesList({
              city: this.delivery.city,
              uselike: true,
            });
            if (this.delivery.items) {
              this.delivery.cityMenu = true;
            }
            if (this.delivery.city !== this.deliveryAddress.city) {
              this.delivery.uuid = '';
            } else {
              this.delivery.uuid = this.deliveryAddress.uuid;
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    onChangeDeliveryAddress(property, addressObject) {
      if (typeof addressObject === 'object') {
        this.deliveryAddress = {
          ...addressObject,
        };
        if (this.deliveryAddress.name) {
          this.delivery.name = this.deliveryAddress.name;
        }
        if (this.deliveryAddress.surname) {
          this.delivery.surname = this.deliveryAddress.surname;
        }
        if (this.deliveryAddress.phone) {
          this.delivery.phone = this.deliveryAddress.phone;
        }
        this.delivery.warehouse = this.deliveryAddress.uuid_ref_warehouse;
        this.delivery.city = this.deliveryAddress.uuid_ref_city;
        // if (this.deliveryAddress.uuid_ref_city) {
        //   this.delivery.city = this.deliveryAddress.uuid_ref_city;
        // }
        // if (this.deliveryAddress.uuid_ref_warehouse) {
        //   this.delivery.warehouse = this.deliveryAddress.uuid_ref_warehouse;
        // }
        this.delivery.email = this.deliveryAddress.email;
        if (this.deliveryAddress.uuid) {
          this.delivery.uuid = this.deliveryAddress.uuid;
        }
      } else if (typeof addressObject === 'string') {
        if (property === 'name') {
          this.delivery.name = addressObject;
        }
        if (property === 'surname') {
          this.delivery.surname = addressObject;
        }
        if (property === 'phone') {
          this.delivery.phone = addressObject;
        }
        if (property === 'city') {
          this.delivery.city = addressObject;
        }
        this.delivery.uuid = '';
      } else if (addressObject === null) {
        this.delivery[property] = null;
      }
    },
    async onCreate(mode = 'save') {
      this.$v.$touch();
      // if (!this.chosenProducts.length) {
      //   this.noItemsError = true;
      //   return;
      // }
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          // formData.append('name', "");
          formData.append('description', this.supplyItem.comment || ``);
          formData.append('uuid_user', this.getUserProperty('uuid'));
          formData.append('type', 'out');
          if (mode === 'send') {
            formData.append('id_state', 401);
          }
          formData.append('delivery_address_name', this.delivery.name);
          formData.append('delivery_address_surname', this.delivery.surname);
          formData.append('delivery_address_phone', this.delivery.phone.replace(/[^+\d]/g, ''));
          formData.append('delivery_address_email', this.delivery.email);
          formData.append('uuid_delivery_address_ref_warehouse', this.delivery.warehouse);
          formData.append('uuid_delivery_address_ref_city', this.delivery.city);
          formData.append('cash_on_delivery', this.supplyItem.cash_on_delivery);
          formData.append('delivery_address_name_city', this.delivery.city_name);
          formData.append('delivery_address_name_warehouse', this.delivery.warehouse_name);
          formData.append('sender_address_name', this.sender.name);
          formData.append('sender_address_surname', this.sender.surname);
          formData.append('sender_address_phone', this.sender.phone.replace(/[^+\d]/g, ''));
          formData.append('sender_address_email', this.sender.email);

          if (this.supplyItem.ttn) {
            formData.append('ttn', this.supplyItem.ttn);
          }
          if (this.supplyItem.cost) {
            formData.append('cost', this.supplyItem.cost);
          }

          const response = await supplyService.addSupplyItem(formData);
          this.onUpdateData(response);
          // orderDialog('addSupplyItem', response);

          this.activeProduct = true;
          this.setSuccessNotification('Постачання успішно створено!');
          // this.chosenProducts = [];
          this.loading = false;
          // this.clearProperty('supplyItem');
          this.$v.$reset();
          // this.noItemsError = false;
          // this.visibility = false;
          EventBus.$emit('supply-out-modified');
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
    async onAddProduct(product, mode) {
      try {
        // orderDialog('onAddProduct', product);
        if (this.supplyItem.uuid) {
          // orderDialog('onAddProduct__uuid', this.supplyItem.uuid);
        } else {
          // orderDialog('onAddProduct__uuid', 'none!!!!');
        }
        const productData = new FormData();
        productData.append('uuid_delivery', this.supplyItem.uuid);
        if (mode === 'addProduct') {
          productData.append('uuid_product', product?.uuid_product);
          productData.append('items_amount', product?.count);
          productData.append('description', product?.product_details?.description);
          if (product.uuid_option) {
            productData.append('uuid_option', product?.uuid_option);
          }
          productData.append('uuid_user', product?.uuid_user);
          // orderDialog('productData', productData);
          supplyService.addDeliveryItemAddProduct(productData).then((respons) => {
            this.setSuccessNotification('Товар добавлено');
            this.onUpdateProd(this.supplyItem.uuid);
            this.activeTab = 0;
          });
        } else if (mode === 'add') {
          productData.append('uuid_item', product.uuid);
          supplyService.addDeliveryItem(productData).then((respons) => {
            this.setSuccessNotification(respons.data.message);
            this.onUpdateProd(this.supplyItem.uuid);
          });
        }
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    async onUpdateProd(uuid) {
      supplyService.getDeliveryItemDetailsByDeliveryUuid(uuid).then((resp) => {
        // // orderDialog('resp', resp);
        this.chosenProducts = resp;
        if (!this.chosenProducts) {
          this.chosenProducts = [];
        }
        this.getProducts();
      });
    },
    async onDelayProduct(uuid) {
      try {
        // orderDialog('onDelayProduct', uuid);
        supplyService.deleteDeliveryItem(uuid).then((respons) => {
          // orderDialog('respons', respons);
          this.setSuccessNotification('Товар вилучено');
          this.getProducts();
        });
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    async onUpdate(mode) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('uuid', this.supplyItem.uuid);
          formData.append('name', this.supplyItem.name);
          formData.append('uuid_deport', this.supplyItem.uuid_deport);
          if (mode === 'send') {
            formData.append('id_state', 401);
          }
          if (this.supply.id_state !== this.supplyItem.id_state && Number(this.supplyItem.id_state) !== 400) {
            formData.append('id_state', this.supplyItem.id_state);
          }
          // orderDialog(mode)
          formData.append('description', this.supplyItem.comment || ``);
          formData.append('ttn', this.supplyItem.ttn);
          formData.append('cost', this.supplyItem.cost);
          formData.append('cash_on_delivery', this.supplyItem.cash_on_delivery);
          // orderDialog('cash_on_delivery', formData.get('cash_on_delivery'))
          formData.append('delivery_address_name', this.delivery.name);
          formData.append('delivery_address_surname', this.delivery.surname);
          formData.append('delivery_address_phone', this.delivery.phone.replace(/[^+\d]/g, ''));
          formData.append('delivery_address_email', this.delivery.email);
          formData.append('uuid_delivery_address_ref_warehouse', this.delivery.warehouse);
          formData.append('uuid_delivery_address_ref_city', this.delivery.city);
          if(this.delivery.city_name){
            formData.append('delivery_address_name_city', this.delivery.city_name);
          }
          formData.append('delivery_address_name_warehouse', this.delivery.warehouse_name);
          formData.append('sender_address_name', this.sender.name);
          formData.append('sender_address_surname', this.sender.surname);
          formData.append('sender_address_phone', this.sender.phone.replace(/[^+\d]/g, ''));
          formData.append('sender_address_email', this.sender.email);

          const response = await supplyService.updateSupplyItem(formData);
          // orderDialog('updateSupplyItem', response.data.object.uuid);
          this.onUpdateData(response.data.object);

          EventBus.$emit('supply-out-modified');
          // this.$root.$emit('updated-notify');
          this.setSuccessNotification('Постачання було успішно оновлено!');
          this.$v.$reset();
          this.loading = false;
          this.visibility = false;
          this.productUuid = '';
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
    async onUpdateState() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('uuid', this.supplyItem.uuid);
          formData.append('id_state', this.supplyItem.id_state);
          formData.append('uuid_deport', this.supplyItem.uuid_deport);

          const response = await supplyService.updateSupplyItem(formData);
          this.onUpdateData(response.data.object);

          EventBus.$emit('supply-out-modified');
          // this.$root.$emit('updated-notify');
          this.setSuccessNotification('Постачання було успішно оновлено!');
          this.$v.$reset();
          this.loading = false;
          this.visibility = false;
          this.productUuid = '';
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
    async onUpdateData(respons) {
      // orderDialog('this.sender', this.sender);
      this.supplyItem = {
        ...respons,
      };
      this.supplyItem.comment = respons.cell_details.description;
      this.sender = {
        ...this.sender,
        items: [],
        name: respons?.sender_address_details?.name,
        surname: respons?.sender_address_details?.surname,
        phone: respons?.sender_address_details?.phone,
      };
      this.senderAddress = {
        ...this.senderAddress,
        ...this.sender,
      };
      this.delivery = {
        ...this.deliveryAddress,
        items: [],
        name: respons?.delivery_address_details?.name,
        surname: respons?.delivery_address_details?.surname,
        phone: respons?.delivery_address_details?.phone,
        warehouse: respons?.delivery_address_details?.uuid_ref_warehouse,
        city: respons?.delivery_address_details?.uuid_ref_city,
      };
      this.deliveryAddress = {
        ...this.deliveryAddress,
        ...this.delivery,
      };
      this.states = await depotService.getAllStates({
        type: 'DELIVERY_OUT',
      });
      const nextStates = await supplyService.getNextStatusId(respons.id_state);
      const nextStateIds = nextStates.map((state) => state.id_state_next);
      if (nextStateIds.length) {
        this.filteredStates = this.states.map(
          // eslint-disable-next-line array-callback-return,consistent-return
          (state) => {
            if (nextStateIds.includes(state.id.toString())) {
              return state;
            }
            if (respons.id_state === state.id.toString()) {
              return state;
            }
          },
        );
        this.filteredStates = this.filteredStates.filter((item) => item);
      }
      // eslint-disable-next-line max-len
      this.chosenProducts = await supplyService.getDeliveryItemDetailsByDeliveryUuid(respons.uuid);
      if (!this.chosenProducts) {
        this.chosenProducts = [];
      }
      this.loading = true;
      await this.getProducts();
      await this.fetchNovaPochtaCities();
      this.activeCities = this.cities;
      await this.fetchWarehouseByRef(this.delivery.warehouse);
    },
    getProductName(itemDetails) {
      return itemDetails?.name;
    },
    getProductDescription(itemDetails) {
      return itemDetails?.description;
    },
    getProductLength(itemDetails) {
      return itemDetails?.size_length;
    },
    getProductWidth(itemDetails) {
      return itemDetails?.size_width;
    },
    getProductHeight(itemDetails) {
      return itemDetails?.size_height;
    },
    getProductWeightNet(itemDetails) {
      return itemDetails?.weight_net;
    },
    clearProperty(property) {
      Object.keys(this[property]).forEach((key) => {
        this[property][key] = '';
      });
      if (property === 'newItem') {
        this.newItem.amount = 1;
      }
    },
    getAddress(addressItem) {
      let result = `${addressItem.city}, вул. ${addressItem.street} ${addressItem.house_number}`;
      if (addressItem.flat_number) {
        result += `, кв. ${addressItem.flat_number}`;
      }
      return result;
    },
    getImagePath(product) {
      return product?.item_details?.path_img;
    },
    async getItemsByBarCode(uuidCode) {
      try {
        this.productsByBarCode = await depotService.getMyProducts({
          uuid_product: uuidCode,
          id_state: 810,
        });
        // // orderDialog('getItemsByBarCode', this.productsByBarCode);
        this.activeTab = 2;
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    sendButtonDisabled() {
      if (this.activeTab === 0) {
        if (
          !this.delivery.warehouse
          || !this.chosenProducts.length
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
