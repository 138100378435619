<template>
  <VDialog v-model="visibility" content-class="cross__dialog"  :transition="$dialogTransition">
    <VCard :style="$vuetify.theme.dark ? 'background:#414141' : 'background:#fbfbfb'">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="visibility = false">
          <v-icon>mdi-close</v-icon>
        </VBtn>
      </div>
      <VCardTitle class="pb-0">
        <v-col class="pa-0" :style="{ fontSize: '18px' }">
          <v-icon  class="mb-1" :style="$vuetify.theme.dark ? 'color:#fbfbfb' : 'color:#414141'">
            mdi-history
          </v-icon>
          <span :style="$vuetify.theme.dark ? 'color:#fbfbfb' : 'color:#414141'" class="title">
            {{ $t("history.item_history") }}
          </span>
          <VBtn plain color="primary" @click="copyToCLipboard"> {{ "#" }} {{ item.ns_code }} </VBtn>
        </v-col>
      </VCardTitle>
      <div class="scroll-container">
        <VCardText class="pt-1">
          <v-row v-if="!$vuetify.breakpoint.smAndDown" align="start">
            <v-col cols="3">
              <ItemInfo
                v-if="item"
                :item="item.product_details"
                :sector="sector"
                :cell="cell"
                :user="user"
                :option_name="option_name"
                :time_created="time_created"
                :time_changed="time_changed"
                :description="description"
                :id_state="id_state"
                :state_name="state_name"
                :state_uk_name="state_uk_name"
              />
            </v-col>
            <!-- <VDivider /> -->
            <v-col cols="9">
              <HistoryInfo :historyInfo="historyInfo" />
            </v-col>
          </v-row>
          <v-row align="start" v-else>
            <v-col cols="12">
              <ItemInfo
                v-if="item"
                :item="item.product_details"
                :sector="sector"
                :cell="cell"
                :user="user"
                :option_name="option_name"
                :time_created="time_created"
                :time_changed="time_changed"
                :description="description"
                :id_state="id_state"
                :state_name="state_name"
                :state_uk_name="state_uk_name"
              />
            </v-col>
            <!-- <VDivider /> -->
            <v-col cols="12">
              <HistoryInfo :historyInfo="historyInfo" />
            </v-col>
          </v-row>
        </VCardText>
      </div>
    </VCard>
  </VDialog>
</template>

<script>
import notifications from "../../mixins/notifications";
import depotService from "../../services/request/depot/depotService";
import usersService from "../../services/request/users/usersService";
import HistoryInfo from "./searchInfo/historyInfo.vue";
import ItemInfo from "./searchInfo/ItemInfo.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ItemInfo,
    HistoryInfo
  },
  data: () => ({
    user: [],
    item: [],
    sector: "",
    cell: "",
    time_created: "",
    option_name: "",
    time_changed: "",
    description: "",
    id_state: "",
    state_name: "",
    state_uk_name: "",
    historyInfo: []
  }),
  mixins: [notifications],
  props: {
    visible: {
      required: true
    },
    itemCod: {
      require: true
    }
  },

  created() {
    this.getItem();
    this.getHistory();
  },
  methods: {
    async copyToCLipboard() {
      try {
        await navigator.clipboard.writeText(this.item.ns_code);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    },
    async getHistory() {
      try {
        this.historyInfo = await depotService.getHistory(this.itemCod);
      } catch (e) {
        console.log(e);
      }
    },
    async getItem() {
      try {
        this.item = await depotService.getItemInfo(this.itemCod);
        this.getUserInfo(this.item.uuid_user);
        this.sector = this.item.sector_details.name;
        this.cell = this.item.cell_details.name;
        this.time_created = this.item.time_created;
        this.time_changed = this.item.time_changed;
        this.description = this.item.product_details.description;
        this.option_name = this.item.option_details.name;
        this.description = this.item.product_details.description;
        this.id_state = this.item.id_state;
        this.state_name = this.item?.state_details?.name;
        this.state_uk_name = this.item?.state_details?.uk_name;
      } catch (e) {
        console.log(e);
      }
    },
    async getUserInfo(event) {
      try {
        this.user = await usersService.getUserByUUID(event);
        if (this.user) {
          this.user = this.user[0];
        } else {
          this.user = null;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-container {
  max-height: 520px;
  overflow-y: auto;
}
</style>
