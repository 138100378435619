<template>
    <VDialog v-model="visibility"  max-width="500px" content-class="cross__dialog"  :transition="$dialogTransition">
      <VCard :style="$vuetify.theme.dark ? 'background:#414141' : 'background:#fbfbfb'" max-height="600px">
        <div class="cross__inner">
          <VBtn
            fab
            small
            elevation="0"
            @click="visibility = false"
          >
            <v-icon>mdi-close</v-icon>
          </VBtn>
        </div>
        <VCardTitle>
          <VCol class="pa-3" :style="{ fontSize: '18px' }">
            {{$t('history.moreDetails')}}
          </VCol>
        </VCardTitle>
        <VCardText max-height="300">
          <v-data-table
            elevation="0"
            :headers="tableHeaders"
            :items="tableItems"
            hide-default-footer
            hide-default-header
            locale="uk"
            dense
            class="pa-3"
            :no-data-text="$t('table.noData')"
          ></v-data-table>
          <VCol class="pa-3" :style="{ fontSize: '16px' }">
            {{$t('history.description')}}
          </VCol>
          <VCol class="pa-3">
            <VTextField readonly v-model="description"/>
          </VCol>
        </VCardText>
      </VCard>
    </VDialog>
</template>

<script>
import depotService from "../../../services/request/depot/depotService";
import langMixin from '@/mixins/language';

export default {
  mixins:[langMixin],
  data: () => ({
    changes: [],
    tmpChanges: []
  }),
  props: {
    visible: {
      required: true
    },
    user: {
      require: true
    },
    time_created: {
      require: true
    },
    time_changed: {
      require: true
    },
    description: {
      require: true
    },
  },
  watch: {
    historyInfo: {
      deep: true,
      handler(e) {
        this.getInfo();
      }
    }
  },
  computed: {
    tableHeaders() {
      return [
        { value: 'column1'},
        { value: 'column2'},
      ];
    },
    tableItems() {
      return [
        {
          column1: this.$t("form.client"),
          column2: this?.user?.name ? `${this.user.name + " " + this.user.surname}` : "--"
        },
        {
          column1: this.$t("form.phone_number"),
          column2: this?.user?.phone ? this.user.phone : "--"
        },
        {
          column1: this.$t("form.email"),
          column2: this?.user?.email ? this.user.email : "--"
        },
        {
          column1: this.$t("form.created_time"),
          column2: this?.time_created ? this.time_created : "--"
        },
        {
          column1: this.$t("form.changed_time"),
          column2: this?.time_changed ? this.time_changed : "--"
        },
      ];
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  methods: {
    getInfo() {
      this.historyInfo.forEach(element => {
        if (element.changes.info_uuid_cell) {
          this.getChangeInfo(element.changes.info_uuid_cell, element, this.$t('history.cell'));
        }
        if (element.changes.info_uuid_deport) {
          this.getChangeInfo(element.changes.info_uuid_deport, element, this.$t('history.storage'));
        }
        if (element.changes.info_uuid_product) {
          this.getChangeInfo(element.changes.info_uuid_product, element, this.$t('history.item'));
        }
        if (Object.keys(this.tmpChanges).length > 0) {
          this.changes.push(this.tmpChanges);
        }
        this.tmpChanges = [];
      });
      // orderDialog(this.changes);
    },
    getChangeInfo(changeInfo, mainInfo, typeText) {
      // orderDialog(changeInfo);
      const params = [];
      params.info = changeInfo;
      params.info.type = typeText;
      this.tmpChanges = params;
      this.tmpChanges.user_name = mainInfo.user_name;
      this.tmpChanges.user_surname = mainInfo.user_surname;
      this.tmpChanges.time_updated = mainInfo.time_updated;
    },
    getChanges() {
      this.changes.forEach(element => {});
    }
  },
};
</script>

<style lang="scss" scoped></style>
