<template>
  <VDialog
    v-model="visibility"
    max-width="400"
    :transition="$dialogTransition"
  >
    <VCard>
      <VCardTitle class="headline">
        Підтвердити видалення
      </VCardTitle>
      <VCardText>
        <strong class="red--text text-center d-block">
          Ви впевнені, що ви хочете видалити дану інформацію?
        </strong>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="12" md="6" sm="12">
            <VBtn
              color="var(--v-green-base)"
              block
              class="w-100"
              dark
              @click="visibility = false"
            >
              Скасувати
            </VBtn>
          </VCol>
          <VCol cols="12" md="6" sm="12">
            <VBtn
              color="var(--v-error-base)"
              block
              dark
              class="w-100"
              @click="onConfirm"
            >
              Видалити
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'ConfirmDeleteDialog',
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    uuid: {
      required: false,
      type: String,
    },
  },
  methods: {
    onConfirm() {
      this.$emit('delete', this.uuid);
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
  },
};
</script>

<style scoped>

</style>
