import "./utils/translations";
import "./assets/styles/common.scss";
import Vue from "vue";
import Vuelidate from "vuelidate";
import draggable from "vuedraggable";
import VueTelInput from "vue-tel-input";
import VueBarcode from "@xkeshi/vue-barcode";
import VueMask from "v-mask";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Snowf from "vue-snowf";
import "vue-tel-input/dist/vue-tel-input.css";
import VueOffline from "vue-offline";

import timeForHumans from "./filters/timeForHumans";
import limitString from "./filters/limitString";
import createdAt from "./filters/createdAt";
import noData from "./filters/noData";
import ToggleButton from "vue-js-toggle-button";
import nsComponents from "@/components/UI";
// ICONS
import PackageAll from "./components/icons/PackageAll.vue";
import PackageDown from "./components/icons/PackageDown.vue";
import PackageUp from "./components/icons/PackageUp.vue";
import PackageOut from "./components/icons/PackageOut.vue";
import Return from "@/components/icons/Return.vue";
import Check from "./components/icons/Check.vue";
import Home from "./components/icons/Home.vue";
import Location from "./components/icons/Location.vue";
import Account from "./components/icons/Account.vue";
import MoveAll from "./components/icons/MoveAll.vue";
import Bell from "./components/icons/Bell.vue";
import Drag from "./components/icons/Drag.vue";
import PPLans from "./components/icons/PPlans.vue";
import CardList from "./components/icons/CardList.vue";
import TechWork from "./components/icons/TechWork.vue";
import timeNow from "./filters/timeNow";
import date from "./filters/date";
import VueCookies from "vue-cookies";

// DIRECTIVES
import VObserver from "@/directives/VObserver";

Vue.config.productionTip = false;

// GLOBAL UI Registration
nsComponents.forEach(component => {
  Vue.component(component.name, component);
});

Vue.component("package-all-icon", PackageAll);
Vue.component("package-down-icon", PackageDown);
Vue.component("return-icon", Return);
Vue.component("package-up-icon", PackageUp);
Vue.component("package-out-icon", PackageOut);
Vue.component("check-icon", Check);
Vue.component("home-icon", Home);
Vue.component("location-icon", Location);
Vue.component("account-icon", Account);
Vue.component("move-all-icon", MoveAll);
Vue.component("bell-icon", Bell);
Vue.component("drag-icon", Drag);
Vue.component("payment-plans-icon", PPLans);
Vue.component("card-list-icon", CardList);
Vue.component("TechWork", TechWork);
Vue.component(VueBarcode.name, VueBarcode);

Vue.filter("timeForHumans", timeForHumans);
Vue.filter("limitString", limitString);
Vue.filter("createdAt", createdAt);
Vue.filter("noData", noData);
Vue.filter("date", date);
Vue.filter("timeNow", timeNow);

// Vue.directive('mask', VueMaskDirective);

Vue.use(VueOffline);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.use(ToggleButton);
Vue.use(VueTelInput, {
  autoDefaultCountry: true,
  inputOptions: {
    placeholder: ""
  },
  dropdownOptions: {
    showDialCodeInSelection: true
  }
});
Vue.directive("observer", VObserver);

Vue.component("draggable", draggable);
Vue.component("Snowf", Snowf);

Vue.prototype.$scrollTop = function(event, elem) {
  let element = document.getElementById("scrollWrappedElement");
  if (elem) {
    element = elem;
  }

  element.scrollTo(0, 0);
};

Vue.prototype.$getChangedData = function(modyData, copyData) {
  const copyDataKeys = Object.keys(copyData);
  const res = [];
  copyDataKeys.forEach(key => {
    if (copyData[key] !== modyData[key]) {
      res.push({ field: key, value: modyData[key] });
    }
  });
  return res;
}
Vue.prototype.$dialogTransition = 'slide-y-transition'

Vue.config.productionTip = false;

Vue.prototype.$checkNumberFloat = function(number, $event) {
    number = number.toString()
    const keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      $event.preventDefault();
    }

    if (number) {
      const checkDigits = number.toString().includes(".")
        ? number.toString().split(".").pop().length
        : 0;
        const digitPosition = number.indexOf('.')
        const cursorPosition = $event.target.selectionStart || digitPosition

      if (checkDigits >= 2 && !window.getSelection().toString().length ) {
        if( cursorPosition > digitPosition){
          $event.preventDefault();
        }
      }
    }
  }

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});

Vue.initI18nManager().then(() => {
  app.$mount("#app");
});
