import _ from "lodash";
import crmService from "@/services/request/crm/crmService";
import user from "@//mixins/user";
import EventBus from "@/events/EventBus";
import supplyService from "@/services/request/supply/supplyService";
import notifications from "@/mixins/notifications";
import depotService from "@/services/request/depot/depotService";

export default {
  computed: {
    getColorForUp() {
      if (this.nameFilterA !== null) {
        if (this.nameFilterA === true) {
          return "grey darken-2";
        } else {
          return "grey lighten-1";
        }
      } else {
        return "grey lighten-1";
      }
    },
    getColorForDown() {
      if (this.nameFilterA !== null) {
        if (this.nameFilterA === true) {
          return "grey lighten-1";
        } else {
          return "grey darken-2";
        }
      } else {
        return "grey lighten-1";
      }
    }
  },
  methods: {
    async onAddProduct(product, mode) {
      try {
        // orderDialog('onAddProduct', product);
        if (this.inputCrmItem.uuid) {
          // orderDialog('onAddProduct__uuid', this.inputCrmItem.uuid);
        } else {
          // orderDialog('onAddProduct__uuid', 'none!!!!');
        }
        const productData = new FormData();
        productData.append("uuid_delivery", this.inputCrmItem.uuid);
        if (mode === "addProduct") {
          productData.append("uuid_product", product?.uuid_product || product?.uuid);
          productData.append("items_amount", product?.count);
          productData.append("description", product?.product_details?.description);
          if (this.settingDialog === "crm" && product.isAdditionSell) {
            productData.append("is_additional_sell", 1);
          }
          if (this.settingDialog === "crm" && product.isStandalone) {
            productData.append("is_standalone", product.isStandalone );
          }
          if (product.uuid_option) {
            productData.append("uuid_option", product?.uuid_option);
          }
          if (this.settingDialog === "supply" && this.permissions.more_than_client) {
            productData.append("uuid_user", product?.uuid_user);
          }
          // orderDialog('productData', productData);
          await supplyService.addDeliveryItemAddProduct(productData);
          this.setSuccessNotification(this.$t("orderDialog.addProduct_success"));
          this.$emit('onHandleChoosenProduct',this.inputCrmItem.uuid)
          this.chipTabsItems = {};
          this.resetCopyItems = !this.resetCopyItems;
          //  if(!this.permissions.client) return // to remove when unique uuid
        EventBus.$emit("supply-out-modified", this.item, true);
        } else if (mode === "add") {
          productData.append("uuid_item", product.uuid);
          await crmService.addCrmItemProduct(productData);
          this.setSuccessNotification(this.$t("orderDialog.addProduct_success"));
          this.$emit('onHandleChoosenProduct',this.inputCrmItem.uuid)
        }
        if (this.settingDialog === "crm") {
          const res = await crmService.isReadyToDeliver(this.item.uuid);
          this.item.ready_to_deliver = res.ready_to_deliver;
        }
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(this.$t("orderDialog.addProduct_error"));
        console.error("fail-addProduct", e);
      }
    },
    async onChoseByBarCode(checked, product, type) {
      if (product.count > product.available && this.settingDialog === "supply") {
        this.setErrorNotification("Кількість товарів перевищена");
      } else {
        // eslint-disable-next-line no-param-reassign
        product = {
          ...product,
          mode: type
        };
        this.chosenProducts = [];
        if (product.mode !== "addProduct") {
          // eslint-disable-next-line no-param-reassign
          product.count = 1;
        }
        this.onAddProduct(product, product.mode);
        // this.getProducts();
        this.searchString = "";
      }
    },
    addProductOption(checked, product, type) {
      const productOption = product.options_details.filter(item => item.uuid === this.checkOption);
      if (productOption.length) {
        if (product.count <= productOption[0].available || this.settingDialog === "crm") {
          // eslint-disable-next-line no-param-reassign
          product.uuid_option = this.checkOption;
          product = {
            ...product,
            mode: type
          };
          this.chosenProducts = [];
          if (product.mode !== "addProduct") {
            // eslint-disable-next-line no-param-reassign
            product.count = 1;
          }
          this.onAddProduct(product, product.mode);
          // this.getProducts();
          this.searchString = "";
          this.checkOption = "";
        } else {
          this.setErrorNotification("Кількість товарів перевищена");
        }
      } else {
        this.setErrorNotification(this.$t("supply.choose_option"));
      }
    },
    getOptionDetailsList(product) {
      if (this.settingDialog === "crm") {
        return product.options_details;
      } else {
        return product.options_details.filter(item => item.available > 0);
      }
    },

    activeTab(e) {
      // this.isChipReset = !this.isChipReset;
      if (e === 1) {
        this.getProducts();

        if (this.settingDialog == "crm") {
          //   this.imagesCheckbox = this.crmImagesCheck
        } else {
          //   this.imagesCheckbox = this.deliveriesImagesCheck
        }
      } else {
        // this.products = []
        this.chipTabsItems = [];
      }
    },
    async nextPage() {
      this.page += 1;
      const params = {};
      if (this.categoryChipTab || this.searchString !== "") {
        if (this.categoryChipTab && this.categoryChipTab !== this.$t("btn.all")) {
          params.product_details__name = this.categoryChipTab;
          params.uselike = true;
        }
        if (this.searchString !== "") {
          params.product_details__name = this.searchString;
          params.uselike = true;
        }
      }
      await this.getProducts(params, true);
    },
    async getProducts(params = {}, next = false) {
      try {
        if (!next) {
          this.page = 1;
        }
        if (next && this.tableAvaibleProductsFilter) {
          params.order = this.tableAvaibleProductsFilter.order;
          params.orderby = this.tableAvaibleProductsFilter.orderby;
        }

        if (next) {
          this.isloadingNextProducts = true;
        } else {
          this.isloadingProducts = true;
        }
        let response = null;
        if (this.settingDialog === "crm") {
          if (this.isCRMProducts) {
            response = await crmService.onlyCrmProductList({
              ...params,
              limit: this.perPage,
              offset: (this.page - 1) * this.perPage
            });
          } else {
            response = await crmService.crmProductList({
              ...params,
              limit: this.perPage,
              offset: (this.page - 1) * this.perPage,
              uuid_board: this.uuid_board
            });
          }
        } else {
          response = await depotService.getMyProducts({
            groupby: "uuid_product",
            // groupby: 'product_details.bar_code',
            id_state: 810,
            ...params,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            uuid_user: this.item.uuid_user
          });
        }

        let newItems = response;
        if(this.availableItemsCheckbox) {
          newItems = [];
          response.forEach((item) => {
            if(item.available > 0) {
              newItems.push(item);
            }
          });
        }


        // eslint-disable-next-line no-restricted-syntax
        for (const item of newItems) {
          item.count = 1;
        }

        if (next) {
          this.products.push(...newItems);
        } else {
          this.products = newItems;
        }

        /*
          // // orderDialog('products', this.products);
          this.products.forEach(product => {
            // eslint-disable-next-line no-param-reassign
            product.amount = product.count;
            // eslint-disable-next-line no-param-reassign
            product.add_uuid_option = null;
            if (product.count < 1) {
              // eslint-disable-next-line no-param-reassign
              product.count = 0;
            } else {
              // eslint-disable-next-line no-param-reassign
              product.count = 1;
            }
          });
          */

        this.loadMoreDisabled = newItems.length < this.perPage;

        if (next) {
          this.isloadingNextProducts = false;
        } else {
          this.isloadingProducts = false;
        }
      } catch (e) {
        if (next) {
          this.isloadingNextProducts = false;
        } else {
          this.isloadingProducts = false;
        }
        this.isloadingProducts = false;
        console.log(e)
      }
    }
  },
  watch:{
    nameFilterA: {
        deep: true,
        handler(e) {
          this.tableAvaibleProductsFilter = {};
          if (this.categoryChipTab && !this.searchString) {
            if (this.categoryChipTab !== this.$t("btn.all")) {
              this.tableAvaibleProductsFilter.product_details__name = this.categoryChipTab;
              this.tableAvaibleProductsFilter.uselike = true;
            }
          } else {
            this.tableAvaibleProductsFilter.product_details__name = this.searchString;
            this.tableAvaibleProductsFilter.uselike = true;
          }
          if (e === true) {
            this.tableAvaibleProductsFilter.order = "ASC";
            this.tableAvaibleProductsFilter.orderby = "product_details.name";

            this.getProducts(this.tableAvaibleProductsFilter, false);
          } else {
            this.tableAvaibleProductsFilter.order = "DESC";
            this.tableAvaibleProductsFilter.orderby = "product_details.name";

            this.getProducts(this.tableAvaibleProductsFilter, false);
          }
        }
      },
  }
};
