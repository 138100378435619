<template>
  <VMenu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" style="z-index:1000"  >
        <span class="d-flex align-center">
          <div class="d-flex align-center ml-2">
            <v-badge
              bottom
              overlap
              color="primary"
              :dot="notify.count == 0"
              :content="notify.count"
              :value="notify.count"
               class="d-flex"
            >
              <img
                width="35"
                class=" userAvatarDrop"
                :src="getUserProperty('image')"
                v-if="getUserProperty('image')"
              />
              <img
                width="35"
                class=" rounded-circle userAvatarDrop"
                :src="user.path_img"
                v-else-if="user.path_img"
              />
              <VIcon class="mr-2" size="28" v-else>mdi-account-circle</VIcon>
            </v-badge>
            <span class="ml-3" :class="{ 'gray-text gray--darken-3': $vuetify.theme.isDark }">
              {{ !$vuetify.breakpoint.smAndDown ? getUserProperty("name") : "" }}
            </span>
          </div>
          <VIcon color="grey" v-if="attrs['aria-expanded'] === 'false'">mdi-menu-down</VIcon>
          <VIcon
            color="grey"
            :class="!$vuetify.breakpoint.smAndDown ? '' : ''"
            v-else-if="attrs['aria-expanded'] === 'true'"
            >mdi-menu-up</VIcon
          >
        </span>
      </span>
    </template>
    <VCard>
      <VList>
        <VListItem>
          <VListItemAvatar class="userAvatarDropList">
            <img width="35" class=" userAvatarDrop"  :src="getUserProperty('image')" v-if="getUserProperty('image')" />
            <img width="35" class=" rounded-circle userAvatarDrop" :src="user.path_img" v-else-if="user.path_img" />
            <Avatar :name="getUserProperty('type')" v-else />
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle>{{ getUserProperty("name") }}</VListItemTitle>
            <VListItemSubtitle v-on:click="copyToClipboard" class="copy-text">{{
              getUserProperty("email")
            }}</VListItemSubtitle>
          </VListItemContent>
        </VListItem>

        <VDivider />

        <VListItem v-if="$vuetify.breakpoint.smAndDown && permissions.balance_client">
          <VListItemTitle>
            <VIcon left>mdi-information</VIcon>
            CRM:
            <span :class="{ 'error--text': getUser.crm_days_left <= 3 }" class="primary--text">
              {{ getUser.crm_days_left }}
            </span>
          </VListItemTitle>
        </VListItem>
        <VListItem v-if="$vuetify.breakpoint.smAndDown && permissions.access_balance">
          <VListItemTitle>
            <VIcon left>mdi-truck-delivery</VIcon>
            <span>
              {{`${$t("crm.your_balance")}:`}}
              <span
                :class="{ 'error--text': deliveryBalance <= 10 }"
                class="primary--text"
                >{{ deliveryBalance }} ({{ negativeBalance }}/{{
                  negativeLimit
                }})</span
              >
            </span>
          </VListItemTitle>
        </VListItem>
        <VListItem @click="navigateTo('profile')" :disabled="isCurrentRoute('profile')">
          <VListItemTitle>
            <VIcon left>mdi-cog</VIcon>
            {{ $t("profile.settings") }}
          </VListItemTitle>
        </VListItem>
        <VListItem
          @click="navigateTo('transactions')" :disabled="isCurrentRoute('transactions')"
          v-show="this.permissions.can_view_transactions_page"
        >
          <VListItemTitle>
            <VIcon left>mdi-credit-card-multiple-outline</VIcon>
            {{ $t("menu.transactions") }}
          </VListItemTitle>
        </VListItem>
        <VListItem
          @click="navigateTo('plans-list')" :disabled="isCurrentRoute('plans-list')"
          v-show="this.permissions.can_view_plans_list_page"
        >
          <VListItemTitle class="d-flex align-center">
            <payment-plans-icon style="height: 24px;" class="mr-3" :fill="iconColor" />
            <span>{{ $t("menu.plans_list") }}</span>
          </VListItemTitle>
        </VListItem>
        <VDivider />
        <VListItem @click="onLogOut">
          <VListItemTitle >
            <VIcon left color="error">mdi-logout</VIcon>
            <span class="text-error">
            {{ $t("btn.log_out") }}
            </span>
          </VListItemTitle>
        </VListItem>
      </VList>
    </VCard>
  </VMenu>
</template>

<script>
import Avatar from "./Avatar.vue";
import user from "../../mixins/user";
import language from "../../mixins/language";
import usersService from "../../services/request/users/usersService";
import notifications from "@/mixins/notifications";
import {mapActions} from "vuex";
import * as actionType from "@/store/modules/usersKey/types/actions";

export default {
  name: "UserDropdown",
  components: {
    Avatar
  },
  data: () => ({
    user: {},
    shown: false
  }),
  mixins: [user, language, notifications],
  props: ["notify", "negative-balance", 'delivery-balance', 'negative-limit'],
  methods: {
    ...mapActions("userKey", {
      setUserKey: actionType.SET_USER_KEY
    }),
    isCurrentRoute(routeName) {
      return this.$route.name === routeName;
    },
    navigateTo(elem) {
      if (!this.isCurrentRoute(elem)) {
        this.shown = false;
        this.$router.push(this.$localize({ name: elem })).catch(() => {});
      }
    },
    async copyToClipboard(e) {
      e.stopPropagation();
      try {
        await navigator.clipboard.writeText(this.getUserProperty("email"));
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
    async onLogOut() {
      this.shown = false;
      window.stop();
      await this.logOut();
      localStorage.removeItem("crm_uuid_board");
      localStorage.removeItem("opened");
      localStorage.removeItem("actived");
      this.setUserKey(null);
      this.$router.push(this.$localize({ name: "login" })).catch(() => {});
    },
    onSettings() {
      this.$emit("settings");
    }
  },
  computed: {
    iconColor() {
      return this.$vuetify.theme.dark ? "#FFFFFF" : "#757575";
    },
  },
  async mounted() {
    // const userInfo = await usersService.getUserUUID(this.getUser.uuid);
    this.user = this.getUser;
    // this.user = {
    //   ...userInfo
    // };
  }
};
</script>

<style scoped>
.userAvatarDrop {
  width: 35px;
  height: 36px;
  object-fit: cover;
  border: thin solid #e3e3e3;
  border-radius: 4px;
}
.userAvatarDropList {
  width: 35px;
  height: 36px;
  object-fit: cover;
  border-radius: 4px;
}
.copy-text {
  text-decoration: underline;
  cursor: pointer;
}
</style>
