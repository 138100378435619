export default {
  LOG_IN: 'Увійти',
  REGISTER: 'Зареєструватися',
  REGISTRATION: 'Реєстрація',
  AUTHORIZATION: 'Авторизація',
  COMPANY: 'Компанія',
  EMAIL_MUST_BE_VALID: 'E-mail повинен бути валідним!',
  EMAIL: 'E-mail',
  NAME: "Ім'я",
  SURNAME: 'Прізвище',
  PHONE_NUMBER: 'Номер телефону',
  PASSWORD: 'Пароль',
  DONT_HAVE_AN_ACCOUNT: 'Немаєте аккаунта?',
  FORGOT_PASSWORD: 'Забули пароль?',
  ALREADY_REGISTERED: 'Вже зареєстровані?',
  WELCOME: 'Ласкаво просимо!',
  SUCCESSFULLY_REGISTERED: 'Ви успішно зареєстровані!',
  NEED_TO_ACTIVE_ACCOUNT: 'Щоб продовжити,вам потрібно активувати свій акаунт',
  HOW_TO_ACTIVE_ACCOUNT: 'Як активувати свій акаунт?',
  CHECK_MAIL: 'Перевірте свою електронну пошту та знайдіть лист із темою «NewSend Activation»',
  ACTIVE_ACCOUNT: 'Виконайте вказівки в листі, та перейдіть за посиланням',
  SUCCES_ACTIVATED: 'Вітаю, ви успішно підтвердили свій акаунт. Якщо виникли якість проблеми,звертайтеся до свого менеджера',
  MAIN: 'Головна',
  USERS: 'Користувачі',
  CLIENTS: 'Клієнти',
  CLIENT: 'Клієнт',
  EMPLOYEE: 'Працівник',
  EMPLOYEES: 'Працівники',
  MANAGERS: 'Менеджери',
  MANAGER: 'Менеджер',
  ADMINISTRATORS: 'Адміністратори',
  ADMINISTRATOR: 'Адміністратор',
  ADMIN: 'Адміністратор',
  OWNERS: 'Власники',
  OWNER: 'Власник',
  DEPOT: 'Склад',
  DEPOTS: 'Склади',
  PRODUCTS: 'Товари',
  SECTORS: 'Сектори',
  SECTOR: 'Сектор',
  CELLS: 'Комірки',
  LOG_OUT: 'Вийти',
  SEARCH: 'Пошук',
  CREATE_DEPOT: 'Створити склад',
  CREATE_SECTOR: 'Створити сектор',
  CREATE_CELL: 'Створити комірку',
  CREATE_ADDRESS: 'Створити адресу',
  ADDRESSES: 'Адреси',
  COUNTRY: 'Країна',
  REGION: 'Область',
  CITY: 'Місто',
  STREET: 'Вулиця',
  HOUSE: 'Будинок',
  FLAT: 'Квартира',
  DESCRIPTION: 'Опис',
  TYPE: 'Тип',
  CANCEL: 'Скасувати',
  CREATE_TTN: 'Створити ТТН',
  CREATE: 'Створити',
  PHONE: 'Номер телефону',
  HOUSE_NUMBER: 'Номер будинку',
  ADDRESS_CREATED: 'Адрес було успішно створено!',
  ADDRESS_UPDATED: 'Адрес було успішно оновлено!',
  DEPOT_CREATED: 'Склад було успішно створено!',
  DEPOT_UPDATED: 'Склад було успішно оновлено!',
  SECTOR_CREATED: 'Сектор було успішно створено!',
  SECTOR_UPDATED: 'Сектор було успішно оновлено!',
  CELL_CREATED: 'Комірка була успішно створена!',
  CELL_UPDATED: 'Комірка була успішно оновлена!',
  NO_DATA_IS_AVAILABLE: 'Жодних доступних даних!',
  UPDATE: 'Оновити',
  SENDINGS: 'Відправлення',
  RECEIVINGS: 'Отримання',
  CREATING_DEPOT: 'Створення складу',
  CREATING_SECTOR: 'Створення сектора',
  UPDATING_SECTOR: 'Оновлення сектора',
  CREATING_CELL: 'Створення комірки',
  UPDATING_CELL: 'Оновлення комірки',
  DEPOT_UUID: 'UUID складу',
  ADDRESS_UUID: 'UUID адреси',
  SECTOR_UUID: 'UUID сектора',
  ADDRESS: 'Адреса',
  TITLE: 'Назва',
  CELL: 'Комірка',
  NS_CODE: 'NS-код',
  BAR_CODE: 'Bar-код',
  MOVE_LIST: 'Список переміщень',
  AMOUNT: 'Кількість',
  WHERE: 'Куди',
  PHONE_NUMBER_MUST_BE_LIKE: 'Номер телефону повинен бути у вигляді +38 (099) 99-99-999',
  PASSWORD_MUST_BE_LIKE: 'Пароль повинен містити великі та малі літери латиниці, цифри та символи, мінімум 8 символів!',
  CREATE_PRODUCT: 'Створити продукт',
  CREATE_SUPPLY: 'Створити постачання',
  NS_CODE_VALIDATION: 'NS_CODE повинен починатися з 88 та складатися з 16 цифр!',
  BAR_CODE_VALIDATION: 'BAR_CODE повинен починатися з 88 та складатися з 16 цифр!',
  ALL_MONTHS : ["Січень", "Лютий", "Березень", "Квітень", "Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"],
  ALL_MONTHS_SMALL : ["Січ", "Лют", "Бер", "Кві", "Тра","Чер","Лип","Сер","Вер","Жов","Лис","Гру"],
  SUCCESS_MESSAGE_ACCEPTED : 'Повідомлення успішно створено та додано в чергу надсилання',
  DELIVERY_PHONE : 'Номер отримувача',
  DELIVERY_CONTAIN_TTN : 'Відправка повинна мати ТТН',
  SMS_MESSAGE : 'SMS повідомлення',
  WRITE_MSG : 'Написати...',
  EMPTY_SMS_LAYOUT : 'У вас поки немає шаблонів',
  ADD_SMS_LAYOUT : 'Додати новий шаблон',
  NAME_SMS_LAYOUT : 'Назва шаблону',
  CHOOSE_FROM_SMS_LAYOUT : 'Вибрати з шаблону',
  USAGE_SMS_LAYOUT : 'Додаток до користування',
  PAYMENT_PLANS : 'Тарифні плани',
  PAYMENT_PLAN : 'Тарифний план',
  PAYMENT_PLANS_DELIVERY_PRICE: 'Ціна за відправку',
  PAYMENT_PLANS_HOURS_PRICE: 'Ціна за h/m³',
  WITHOUT_PAYMENT_PLAN: 'У вас не вибраний тарифний план, зверніться до менеджера щоб вам обрали тарифний план',
  isRequired(constant) {
     return `${constant}  обов'язкове поле для заповнення!`;
  },
  notEngTxt(constant) {
     return `${constant}  повинне бути кирилицею!`;
  },
};
