// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const TURBOSMS_URL = `https://api.turbosms.ua/message/send.json/`;
const prefix = '/extensions/sms'

export default {
  // async sendSMS(data) {
  //    
  //   const response = await requestService.getCustom(`https://api.turbosms.ua/message/send.json?recipients[0]=${data.recipients[0]}&sms[sender]=${data.sms.sender}&sms[text]=${data.sms.text}&token=${data.token}`);
  //   return response?.data
  // },
  // async sendSMS(data) {
  //    
  //   const response = await requestService.postCustom(TURBOSMS_URL,data);
  //   return response?.data
  // },
  async getbalanceSMS(data) {
     
    const response = await requestService.getCustom(`https://api.turbosms.ua/user/details.json?token=${data.token}`);
    return response?.data
  },
  async getLayoutList(){
    const response = await requestService.get(`${prefix}/templates/list`);
    return response?.data?.object
  },
  async addLayout(payload){
    const response = await requestService.post(`${prefix}/templates/add`,payload);
    return response?.data?.object
  },
  async updateLayout(payload){
    const response = await requestService.post(`${prefix}/templates/update`,payload);
    return response?.data?.object
  },
  async sendSMS(payload){
    const response = await requestService.post(`${prefix}/send`,payload,{},false);
    return response?.data
  },
  async deleteLayout(uuid){
    const response = await requestService.delete(`${prefix}/templates/delete/${uuid}`);
    return response?.data
  }
};



