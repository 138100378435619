import { mapGetters, mapActions } from 'vuex';
import * as getterTypes from '../store/modules/availableItemsCheckbox/types/getters';
import * as actionTypes from '../store/modules/availableItemsCheckbox/types/actions';

export default {
    methods: {
        ...mapActions('availableItemsCheckbox', {
            fetchCrmItemsCheck: actionTypes.FETCH_CRM_ITEMS_CHECK,
        }),
        ...mapActions('availableItemsCheckbox', {
            fetchDeliveriesItemsCheck: actionTypes.FETCH_DELIVERIES_ITEMS_CHECK,
        }),
    },
    computed: {
        ...mapGetters('availableItemsCheckbox', {
          crmItemsCheck: getterTypes.GET_CRM_ITEMS_CHECK,
        }),
        ...mapGetters('availableItemsCheckbox', {
            deliveriesItemsCheck: getterTypes.GET_DELIVERIES_ITEMS_CHECK,
        }),
    },
};
