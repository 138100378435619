<template>
  <div>
    <VCol class="pa-0">
      <VCard elevation="0">
        <VCardText max-height="300">
          <v-data-table
            elevation="0"
            :headers="tableHeaders"
            :items="changeItems"
            item-key="email"
            hide-default-footer
            locale="uk"
            dense
            loader-height="1"
            fixed-header
            :items-per-page="changeItems.length"
            :no-data-text="$t('table.noData')"
          >
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.time_updated="{ item }">
              <div>
                {{ item.time_updated ? item.time_updated : " -- " }}
              </div>
            </template>
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.action="{ item }">
              <div>
                {{ "Переміщення" }}
              </div>
            </template>
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.info.type="{ item }">
              <div>
                {{ item.info.type ? item.info.type : " -- " }}
              </div>
            </template>
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.info.old_value="{ item, index }">
              <div>
                {{ item.info.ns_code ? item.info.ns_code : " -- " }}
              </div>
            </template>
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.info.new_value="{ item, index }">
              <div>
                {{ getNewValue(index) }}
              </div>
            </template>
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.user_name="{ item }">
              <div>
                {{ item.user_name + " " + item.user_surname }}
              </div>
            </template>
          </v-data-table>
        </VCardText>
      </VCard>
    </VCol>
  </div>
</template>

<script>
import depotService from "../../../services/request/depot/depotService";
import langMixin from "@/mixins/language";

export default {
  mixins: [langMixin],
  data: () => ({
    changes: [],
    tmpChanges: []
  }),
  props: {
    historyInfo: {
      require: true
    }
  },
  watch: {
    historyInfo: {
      deep: true,
      handler(e) {
        this.getInfo();
      }
    }
  },
  computed: {
    sortedItems() {
      return [...this.changeItems].sort((a, b) => {
        const dateA = new Date(a.time_updated);
        const dateB = new Date(b.time_updated);

        return dateB - dateA;
      });
    },
    tableHeaders() {
      return [
        { text: this.$t("history.timeChanged"), value: "time_updated", sortable: true },
        { text: this.$t("history.action"), value: "action" },
        { text: this.$t("history.type"), value: "info.type" },
        { text: this.$t("history.oldValue"), value: "info.old_value" },
        { text: this.$t("history.newValue"), value: "info.new_value" },
        { text: this.$t("history.initiator"), value: "user_name" }
      ];
    },
    changeItems() {
      const changedItems = [];
      this.changes.forEach(change => {
        const item = { ...change };
        changedItems.push(item);
      });
      return changedItems;
    }
  },
  methods: {
    getNewValue(index) {
      const noInfo = "--";
      const newNsCode = this.changeItems[index + 1]?.info?.ns_code;

      return newNsCode ? newNsCode : noInfo;
    },
    getInfo() {
      this.historyInfo.forEach(element => {
        if (element.changes.info_uuid_cell) {
          this.getChangeInfo(element.changes.info_uuid_cell, element, this.$t("history.cell"));
        }
        if (element.changes.info_uuid_deport) {
          this.getChangeInfo(element.changes.info_uuid_deport, element, this.$t("history.storage"));
        }
        if (element.changes.info_uuid_product) {
          this.getChangeInfo(element.changes.info_uuid_product, element, this.$t("history.item"));
        }
        if (Object.keys(this.tmpChanges).length > 0) {
          this.changes.push(this.tmpChanges);
        }
        this.tmpChanges = [];
      });
      // orderDialog(this.changes);
    },
    getChangeInfo(changeInfo, mainInfo, typeText) {
      // orderDialog(changeInfo);
      const params = [];
      params.info = changeInfo;
      params.info.type = typeText;
      this.tmpChanges = params;
      this.tmpChanges.user_name = mainInfo.user_name;
      this.tmpChanges.user_surname = mainInfo.user_surname;
      this.tmpChanges.time_updated = mainInfo.time_updated;
      // console.log(mainInfo);
    },
    getChanges() {
      this.changes.forEach(element => {});
    }
  }
};
</script>

<style lang="scss" scoped></style>
