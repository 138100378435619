// eslint-disable-next-line import/no-cycle
import requestService from '../requestService';

const API_KEY = '5bc5d2947860438c2055ab92fafdf4c8';
const ENDPOINT_URL = '/np/request';
const NOVAPOCHTA_URL = 'https://api.novaposhta.ua/v2.0/json/';

export default {
  async searchByTTN({ ttn, phone }) {
    //
    //
    const document = {};
    if (phone) {
      document.Phone = phone;
    }
    const response = await requestService.post(
      ENDPOINT_URL,
      {
        apiKey: API_KEY,
        modelName: 'TrackingDocument',
        calledMethod: 'getStatusDocuments',
        methodProperties: {
          Documents: [
            {
              DocumentNumber: ttn,
              ...document,
            },
          ],
        },
      },
    );

    return response?.data?.data;
  },
  async getAllCities(payload) {
    const params = {
      FindByString: payload?.searchQuery || '',
    }
    if (payload?.ref) {
      params.Ref = payload?.ref
    }
    if (payload?.uuid_order) {
      params.uuid_order = payload?.uuid_order
    }
    if (payload?.uuid_board) {
      params.uuid_board = payload?.uuid_board
    }
    if (payload?.page) {
      params.Page = payload?.page
    }
    if (payload?.perPage) {
      params.Limit = payload?.perPage
    }
    const response = await requestService.get(
      '/np/address/cities',
      params
    );
    return response?.data?.object;
  },
  async getAllSettlements(payload) {
    const response = await requestService.get('/np/address/settlements', payload);
    return response?.data?.object;
  },
  async getAllStreets(payload) {
    const response = await requestService.get(
      '/np/address/streets',
      payload,
    );
    return response?.data?.object;
  },
  // eslint-disable-next-line
  async getWarehousesByCityRef(data) {

    if (data.cityRef) {
      if(data.cityRef == "00000000-0000-0000-0000-000000000000") {
        data.cityRef = ""
      }
      const params = {
        CityRef: data.cityRef,
        Page: data.page,
        FindByString: data.searchQuery,
        Limit: data.perPage
      }
      if (data?.uuid_order) {
        params.uuid_order = data.uuid_order
      }
      if (data?.uuid_board) {
        params.uuid_board = data.uuid_board
      }
      const response = await requestService.get(
        "/np/address/warehouses",
        params
      );
      return response?.data?.object;
    }
    else {
      return []
    }
  },
  async getWarehouse(ref) {
    if (ref) {
      const response = await requestService.get(
        "/np/address/warehouses",
        {
          Ref: ref,
        },
      );
      return response?.data?.object;
    }
    else {
      return []
    }
  },
  async getCitiesByRegionRef(regionRef) {
    const response = await requestService.get(
      '/np/address/streets',
      {
        RegionRef: regionRef,
      },
    );
    return response?.data?.object;
  },
  async getDataByTTN(ttn) {
    const response = await requestService.post(
      ENDPOINT_URL,
      {
        apiKey: API_KEY,
        modelName: 'TrackingDocument',
        calledMethod: 'getStatusDocuments',
        methodProperties: {
          Documents: [
            {
              DocumentNumber: ttn,
              Phone: '+380937010009',
            },
          ],
        },
      },
    );
    return response?.data?.data;
  },
  async getMovement(ttn) {
    const response = await requestService.post(
      ENDPOINT_URL,
      {
        apiKey: 'f0a192955bdefd1dd4c2942624d127b5',
        modelName: 'InternetDocument',
        system: "Tracking",
        calledMethod: 'getDocumentsEWMovement',
        methodProperties: {
          NewFormat: 1,
          Number: ttn,
        },
      },
    );
    return response?.data?.data[0];
  },
  async postCreateTTN(data) {
    const response = await requestService.postCustom(NOVAPOCHTA_URL, JSON.stringify(data), false);
    return response;
  },
  async dummyJSON() {
    const response = await requestService.postCustom("https://dummyjson.com/http/503");
    return response;
  },
  async getCounterpartyList(params) {
    const response = await requestService.get('/np/counterparties/list', params, {}, false);
    return response.data.object;
  },
  async daleyCounterpartyItem(uuid) {
    const response = await requestService.delete(`/np/counterparties/delete/${uuid}`);
    return response.data.object;
  },
  async setDefaultCounterparty(uuid) {
    const response = await requestService.patch(`/np/counterparties/default/${uuid}`);
    return response.data.object;
  },
  async updateCounterpartyItem(data) {
    const response = await requestService.post('/np/counterparties/update', data);
    return response.data.object;
  },
  async addCounterpartyItem(data) {
    const response = await requestService.post('/np/counterparties/add', data);
    return response;
  },
  async getCargoList(params) {
    const response = await requestService.get('/np/cargo/description/list', params);
    return response.data.object;
  },
};
