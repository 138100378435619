<template>
  <v-card class="chart__wrapper" :loading="isLoading" :disabled="isLoading">
    <v-card-text style="width:100%">
      <v-chart
      ref="myChart"
        class="chart-container"
        :option="option"
        :style="customStyles"
        autoresize
        :loading="isLoading"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import { BarChart, LineChart } from "echarts/charts";
import { mapGetters } from "vuex";
import {
  ToolboxComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent
} from "echarts/components";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent
]);

export default {
  components: {
    VChart
  },
  data: () => ({
    customStyles: {
      width: '100% !important',
      height: '400%'
    },
    option: {
      animationDuration: 1000,
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [],
        splitLine: {
          show: false
        }
      },

      yAxis: {
        axisLine: { onZero: false },
        type: "value",
        splitLine: {
          show: true
        }
      },
      title: {
        left: "center",
        top: "center"
      },
      dataZoom: [
        {
          type: "inside"
        }
      ],
      tooltip: {
        axisPointer: {
          type: "line"
        },
        trigger: "axis"
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      legend: {data:[]},
      series: [
        {
          data: [],
          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 10,
          color: "#3874CB",

          lineStyle: {
            width: 4
          },
          itemStyle: {
            borderRadius: [5, 5, 0, 0]
          },
          areaStyle: {
            color: "#3873cb78"
          }
        }
      ]
    },
    initialChartData: {},
  }),
  props: {
    chartData: {
      require: true
    },
    resetChart: {
      require: true
    },
    type: {
      require: true
    },
    isLoading: {
      require: true,
      type: Boolean
    }
  },
  mounted() {
    this.initialChartData = JSON.parse(JSON.stringify(this.option));
    if(this.type === "IConnection") {
      this.customStyles.height = "300%";
      this.option.title.text = this.$t("crm.pleaseWait");
    } else {
      this.option.title.text = this.$t("crm.choseFilter");
    }
  },
  methods: {
    onResetChart() {
      this.option = JSON.parse(JSON.stringify(this.initialChartData));
      this.$refs.myChart.clear();
      this.$forceUpdate()
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"])
  },
  watch: {
    resetChart(e) {

      this.onResetChart();
    },
    chartData: {
      deep: true,
      handler(dataset) {
        this.onResetChart();
        if (dataset.length && this.type === "IConnection") {
          const data = dataset;
          const labels = [];
          data.forEach(item => {
            labels.push(item + 'ms')
          });
          this.option.xAxis.data = ['0 s', '5 s', '10 s',  '15 s', '20 s', '25 s', '30 s', '35 s', '40 s', '45 s', '50 s', '55 s', '60 s', '65 s', '70 s', '75 s', '80 s', '85 s', '90 s', '95 s'];
          this.option.series[0].data = data.map(e => e);
          if (!data.length) {
            this.option.title.text = this.$t("table.noData");
          } else {
            this.option.title.text = "";
          }
        } else if (dataset.length && this.type === "basic") {
          const data = dataset;
          const labels = [];
          data.forEach(item => {
            labels.push(item.date.substr(5));
          });
          this.option.xAxis.data = [...labels];
          this.option.series[0].data = data.map(e => e.amount);
          if (!data.length) {
            this.option.title.text = this.$t("table.noData");
          } else {
            this.option.title.text = "";
          }
        } else if (dataset.length && this.type === "assigned") {
          const data = dataset;
          this.option.yAxis = {
            type: "category",
            data: data.map(e => (e.name || "Не призначено") && (e.surname || "Не визначено"))
          };
          this.option.xAxis = {
            type: "value",
            boundaryGap: false
          };

          this.option.series = [];
          this.option.series[0] = {
            type: "bar",
            data: data.map(e => e.amount),
            barWidth: "20%",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)"
            }
          };
          this.option.tooltip.axisPointer.type = "shadow";
          if (!data.length) {
            this.option.title.text = this.$t("table.noData");
          } else {
            this.option.title.text = "";
          }
        } else if (dataset.length && this.type === "profit") {
          const data = dataset;
          this.option.xAxis = {
            type: "category",
            data: data.map(d => d.date.slice(0, 10)),
            boundaryGap: true
          };
          this.option.yAxis = {
            type: "value"
          };
          this.option.series = [
            {
              name: this.$t("table.TotalPrice") + ` (${this.$t("paymentPlan.uah")})`,
              type: "bar",
              barGap: 0,
              emphasis: {
                focus: "series"
              },
              data: data.map(d => d.total_price || 0)
            },
            {
              name: this.$t("table.InitPrice") + ` (${this.$t("paymentPlan.uah")})`,
              type: "bar",
              barGap: 0,
              emphasis: {
                focus: "series"
              },
              data: data.map(d => d.total_price_init || 0)
            },
            {
              name: this.$t("table.MarginPrice") + ` (${this.$t("paymentPlan.uah")})`,
              type: "bar",
              barGap: 0,
              emphasis: {
                focus: "series"
              },
              data: data.map(d => d.total_price_profit || 0)
            }
          ];
          this.option.tooltip.axisPointer.type = "shadow";
          this.option.tooltip.axisPointer.trigger = "";
          this.option.grid = {
            left: "1%",
            right: "3%",
            bottom: "3%",
            top: "3%",
            containLabel: true
          };
          if (!data.length) {
            this.option.title.text = this.$t("table.noData");
          } else {
            this.option.title.text = "";
          }
        } else if (dataset.object?.date && this.type === "tags") {
          const data = dataset.object;
          const keys = Object.keys(data?.date);
          if (!keys.length) {
            this.option.title.text = this.$t("table.noData");
          } else {
            this.option.title.text = "";
          }
          this.option.yAxis = {
            type: "value",
            data: data
          };
          this.option.xAxis = {
            type: "category",
            boundaryGap: false
          };
          this.option.tooltip.axisPointer.type = "shadow";
          this.option.xAxis.boundaryGap = true;

          this.option.xAxis.data = keys;
          const result = [];
          const allTags = Object.keys(data.tags).map(e => e || "none");

          keys.forEach((d, index) => {
            this.option.series[0];
            const dataItem = data?.date[d];
            const tagKeys = Object.keys(dataItem.tags);

            tagKeys.forEach(item => {
              if (item in result) {
                result[item].push(dataItem.tags[item]);
              } else {
                result[item] = [dataItem.tags[item]];
              }
            });
          });
          Object.keys(result).forEach(res => {
            const param = {
              data: result[res],
              name: res || "none",
              type: "line",
              stack: "Total",
              areaStyle: {},
              emphasis: {
                focus: "series"
              }
            };
            this.option.tooltip = {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                label: {
                  backgroundColor: "#6a7985"
                }
              }
            };
            this.option.toolbox = {
              feature: {
                saveAsImage: {}
              }
            };
            this.option.series.push(param);
            this.option.legend = {
              data: allTags
            };
          });
        }else{
          this.onResetChart()
        }

      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chart {
  &__wrapper {
    border: thin solid #e4e4e4;
    border-radius: 5px;
  }
}
</style>
