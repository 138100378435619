<template>
  <VRow class="pa-0 ma-0">
    <VCol cols="12" sm="6" md="4">
      <div class="crmDialogItem__utm">
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_source</div>
          <img src="/img/icom_utm.svg" alt="" />
          <div class="value">{{ data.utm_source || "--" }}</div>
        </div>
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_medium</div>
          <img src="/img/icom_utm.svg" alt="" />
          <div class="value">{{ data.utm_medium || "--" }}</div>
        </div>
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_term</div>
          <img src="/img/icom_utm.svg" alt="" />
          <div class="value">{{ data.utm_term || "--" }}</div>
        </div>
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_content</div>
          <img src="/img/icom_utm.svg" alt="" />
          <div class="value">{{ data.utm_content || "--" }}</div>
        </div>
        <div class="crmDialogItem__utm-item">
          <div class="name">utm_campaign</div>
          <img src="/img/icom_utm.svg" alt="" />
          <div class="value">{{ data.utm_campaign || "--" }}</div>
        </div>
      </div>
    </VCol>
    <VCol cols="12" sm="6" md="4" v-if="data.uuid_user == getUser.uuid || isAllowAssignedUserChange">
      <VRow align="center">
        <VCol cols="4" sm="5" lg="4">
          <div>{{$t('orderDialog.assign_to')}}</div>
        </VCol>
        <VCol>
          <v-select
            class="text-caption"
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details
            dense
            outlined
            label=""
            item-value="uuid_user"
            :items="users"
            @change="showConfirmSend = true"
            v-model="assignedUser"
            color="success"
            item-color="success"
          >
            <template v-slot:item="{ item }">
              <span class="text-body-2"
                >{{ item.user_details.name }}
                {{ item.user_details.surname }}</span
              >
            </template>
            <template v-slot:selection="{ item }">
              {{ item.user_details.name }} {{ item.user_details.surname }}
            </template>
          </v-select>
        </VCol>
      </VRow>
    </VCol>
    <ConfirmDialogSlots
      :visible="showConfirmSend"
      @close="showConfirmSend = false"
      @onConfirm="
        onChangeUser();
        showConfirmSend = false;
      "
      @onCancel="showConfirmSend = false; assignedUser = previousUser"
      :typeAlert="'success'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.confirmAssigned.title") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.confirmAssigned.desc") }}
      </template>
    </ConfirmDialogSlots>
  </VRow>
</template>

<script>
import crmService from "@/services/request/crm/crmService";
import user from "@/mixins/user";
import ConfirmDialogSlots from "@/components/dialog/ConfirmDialog.vue";
import EventBus from "@/events/EventBus";

export default {
  name: "infoDialogCrm",
  components: {ConfirmDialogSlots},
  mixins: [user],
  data: () => ({
    users: [],
    assignedUser: null,
    showConfirmSend: false,
    previousUser: 0
  }),
  props: {
    isAllowAssignedUserChange: {
      require:true,
    },
    data: {
      type: Object,
      require: true,
    },
  },
  async mounted() {
    await this.getSharedUsers();
  },
  methods: {
    onChangeUser() {
      this.$emit('onChangeAssignedUser', this.assignedUser);
      const user = this.users.find(item => item.uuid_user == this.assignedUser);
        if (user) {
          EventBus.$emit("header-crm-assigned-user", user);
        }
        this.previousUser = this.assignedUser;
      },
    async getSharedUsers() {
      try {
        const params = {
          uuid_board: this.data.uuid_board,
        };
        const res = await crmService.shareCrmBordUserList(params);

        res.unshift(
          {
            uuid_user:"00000000-0000-0000-0000-000000000000",
              user_details:{
                name:this.$t('form.not_selected')
              }
          }
        )
        this.users = res;

        this.assignedUser = this.data.uuid_user_assigned_to
      } catch (e) {
        console.log(e)
      }
      this.previousUser = this.assignedUser;
    },
  },
};
</script>

<style scoped lang="scss">
.crmDialogItem__utm {
  &-item {
    display: flex;
    margin-bottom: 12px;
    .name {
      display: block;
      width: 130px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #676767;
    }
    .value {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #676767;
    }
    img {
      margin-right: 24px;
    }
  }
}
</style>
