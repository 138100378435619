export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
  SYSTEM: "system",
};

export const MENU = [
  {
    icon: "mdi-account-outline",
    text: "user",
    permission: "can_see_user_menu",
  },
  {
    icon: "mdi-folder-key-outline",
    text: "api_keys",
    permission: "can_see_api_keys_menu",
  },
  {
    icon: "mdi-lock-outline",
    text: "password",
    permission: "can_see_password_menu",
  },
  {
    icon: "mdi-wrench-cog-outline",
    text: "interface",
    permission: "can_see_theme_menu",
  },
];
export const DEFAULT_STORAGE = {
  ui: {
    isDark: false,
    isLarge: false,
    userKey: null
  },
  settings: {
    image_check: {
      supply: false,
      crm: false,
    },
  },
};
