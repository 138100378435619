import * as actionTypes from "./types/actions";
import * as mutationTypes from "./types/mutations";

export default {
  [actionTypes.SET_TOTAL_OUT]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_TOTAL_OUT, value);
  },
  [actionTypes.SET_TOTAL_IN]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_TOTAL_IN, value);
  },
  [actionTypes.SET_TOTAL_RETURN]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_TOTAL_RETURN, value);
  }
};
