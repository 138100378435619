<template>
  <div>
    <VCol class="pa-0">
      <VCard elevation="0" min-height="452">
        <VCardText>
          <div class="info-img mb-0" v-if="!newItem.path_img">
            <img
              :src="require('@/assets/images/product-desc.png')"
              alt=""
              style="height: 100%; width:100%; object-fit: cover"
            />
          </div>
          <div class="info-img mb-0" v-else>
            <img class="br-10" :src="newItem.path_img" alt="" />
          </div>
          <p class="subtitle-2 mb-0 mt-5 ">
            {{ $t("history.product") }}
          </p>

          <v-data-table
            elevation="0"
            disable-sort
            :headers="productsHeaders"
            :items="productsItems"
            :no-data-text="$t('table.noData')"
            locale="uk"
            dense
            hide-default-header
            hide-default-footer
          >
          <template v-slot:item="{ item }">
              <div class="d-flex align-center">
                <VCol cols="6" class="px-1 py-1">
                  {{ item.column1 }}
                </VCol>
                <VCol cols="6" class="px-1 py-1">
                  {{ item.column2 }}
                </VCol>
              </div>
            </template>
        </v-data-table>
          <p class="subtitle-2 mb-0 mt-5 ">
            {{ $t("products.option") }}
          </p>

          <v-data-table
            elevation="0"
            disable-sort
            :headers="optionHeaders"
            :items="optionItems"
            :no-data-text="$t('table.noData')"
            locale="uk"
            dense
            hide-default-header
            hide-default-footer
          >
          <template v-slot:item="{ item }">
              <div class="d-flex align-center">
                <VCol cols="6" class="px-1 py-1">
                  {{ item.column1 }}
                </VCol>
                <VCol cols="6" class="px-1 py-1">
                  {{ item.column2 }}
                </VCol>
              </div>
            </template>
        </v-data-table>
          <p class="subtitle-2 mb-0 mt-2 ">
            {{ $t("history.placement") }}
          </p>

          <v-data-table
            elevation="0"
            disable-sort
            :headers="placingHeaders"
            :items="placingItems"
            :no-data-text="$t('table.noData')"
            locale="uk"
            dense
            hide-default-header
            hide-default-footer
          >
          <template v-slot:item="{ item }">
              <div class="d-flex align-center">
                <VCol cols="6" class="px-1 py-1">
                  {{ item.column1 }}
                </VCol>
                <VCol cols="6" class="px-1 py-1">
                  {{ item.column2 }}
                </VCol>
              </div>
            </template>
        </v-data-table>
          <VRow class="text-center mt-3">
            <VCol cols="12">
              <v-btn @click="visible = true" text class="pa-3 mt-2" block>
                {{ $t("history.moreDetails") }}
                <v-icon right>mdi-arrow-expand-right</v-icon>
              </v-btn>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VCol>
    <ShowMoreDetailsDialog
      :visible="visible"
      @close="visible = false"
      v-if="visible"
      :user="user"
      :time_created="time_created"
      :time_changed="time_changed"
      :description="description"
    />
  </div>
</template>

<script>
import ShowMoreDetailsDialog from "@/components/layout/searchInfo/ShowMoreDetailsDialog.vue";

export default {
  components: { ShowMoreDetailsDialog },
  data: () => ({
    newItem: {},
    visible: false
  }),
  props: {
    item: {
      require: true
    },
    option_name: {
      require: true
    },
    sector: {
      require: true
    },
    user: {
      require: true
    },
    cell: {
      require: true
    },
    time_created: {
      require: true
    },
    time_changed: {
      require: true
    },
    description: {
      require: true
    },
    id_state: {
      require: true
    },
    state_name: {
      require: true
    },
    state_uk_name: {
      require: true
    }
  },
  computed: {
    productsHeaders() {
      return [{ value: "column1" }, { value: "column2" }];
    },
    productsItems() {
      return [
        {
          column1: this.$t("history.title"),
          column2: this.newItem.name || "--"
        },
        {
          column1: this.$t("table.bar_code"),
          column2: this.newItem.bar_code || "--"
        }
      ];
    },
    optionHeaders() {
      return [{ value: "column1" }, { value: "column2" }];
    },
    optionItems() {
      return [
        {
          column1: this.$t("history.title"),
          column2: this.option_name || "--"
        }
      ];
    },
    placingHeaders() {
      return [{ value: "column1" }, { value: "column2" }];
    },
    placingItems() {
      return [
        {
          column1: this.$t("form.sector"),
          column2: this?.sector ? this.sector : "--"
        },
        {
          column1: this.$t("form.cell"),
          column2: this?.cell ? this.cell : "--"
        },
        {
          column1: this.$t("table.status"),
          column2: this?.state_uk_name ? this.state_uk_name : this.state_name || "--"
        }
      ];
    }
  },
  watch: {
    item: {
      deep: true,
      handler(e) {
        this.newItem = e;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.info-img {
  margin-bottom: 1rem;
  width: 100%;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.ccol {
  width: auto;
  flex-grow: unset;
  flex-basis: unset;
  padding-right: 0;
}
.table_description {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
