import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import auth from './modules/auth';
import language from './modules/language';
import notifications from './modules/notifications';
import loader from './modules/loader';
// eslint-disable-next-line import/no-cycle
import crm from './modules/crm';
// eslint-disable-next-line import/no-cycle
import novaPochta from './modules/novaPochta';
import supplyCart from './modules/supplyCart';
import sizeUI from './modules/sizeUI';
import monitorKey from './modules/monitorKey';
// eslint-disable-next-line import/no-cycle
import theme from './modules/theme';
// eslint-disable-next-line import/no-cycle
import moveList from './modules/moveList';
// eslint-disable-next-line import/no-cycle
import requestsAmount from './modules/requestsAmount'
// eslint-disable-next-line import/no-cycle
import imagesCheckbox from './modules/imagesCheckbox'
import availableItemsCheckbox from "@/store/modules/availableItemsCheckbox";
import pageLoadTime from "@/store/modules/pageLoadTime";
import userKey from "@/store/modules/usersKey";
import deportKey from "@/store/modules/deportOwnerSupplyKey";
import totalValues from "@/store/modules/totalValues";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    language,
    notifications,
    loader,
    crm,
    novaPochta,
    supplyCart,
    sizeUI,
    theme,
    moveList,
    requestsAmount,
    imagesCheckbox,
    availableItemsCheckbox,
    monitorKey,
    pageLoadTime,
    userKey,
    deportKey,
    totalValues
  },
});
