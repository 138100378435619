// eslint-disable-next-line import/no-cycle
import authService from '../../../services/request/auth/authService';
// eslint-disable-next-line import/no-cycle
import usersService from '../../../services/request/users/usersService';
import * as actionTypes from './types/actions';
import * as mutationTypes from './types/mutations';
import * as cartMutations from '../supplyCart/types/mutations';
import * as crmMutations from '../crm/types/mutations';

export default {
  [actionTypes.LOG_IN]: async ({ commit }, payload) => {
    const response = await authService.logIn(payload);
    commit(mutationTypes.SET_USER_DATA, response);
  },
  [actionTypes.USER_UPDATE_DATA]: async ({ commit }, payload) => {
    const response = await usersService.updateUser(payload);
    //  
    commit(mutationTypes.SET_USER_DATA, response.data.object);
  },
  [actionTypes.REGISTER]: async (context, payload) => {
    await authService.register(payload);
  },
  [actionTypes.DEPORTS]: async () => {
    const response = await authService.deports();
    return response;
  },
  [actionTypes.LOG_OUT]: async ({ commit }) => {
    await authService.logOut();
    commit(`supplyCart/${cartMutations.CLEAR_CART}`, null, { root: true });
    commit(`crm/${crmMutations.SET_UUID_BOARD}`, '', { root: true });
    commit(`crm/${crmMutations.SET_UUID_TAB}`, '', { root: true });
    commit(mutationTypes.LOG_OUT);
  },
  [actionTypes.FETCH_USER_DATA]: async ({ commit }) => {
    const response = await authService.fetchAuthUserData();
    commit(mutationTypes.SET_USER_DATA, response);
  },
  [actionTypes.SHOW_500_ERROR_DIALOG]: ({ commit },status) => {
    commit(mutationTypes.SET_SHOW_500_ERROR_DIALOG,status);
  },
  [actionTypes.CHANGE_USER_DATA_BY_KEY]: ({ commit }, payload) => {
    commit(mutationTypes.CHANGE_USER_DATA_BY_KEY, payload);
  },
};
