
import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/sizeUI/types/actions";
import * as getterTypes from "@/store/modules/sizeUI/types/getters";

const SIZES = {
  LARGE: "large",
  DEFAULT: "default"
};
export default {
  data: () => ({
    SIZE_NAMES:SIZES,
    SIZES: [
      {
        text: SIZES.DEFAULT,
        icon: "mdi-size-m"
      },
      {
        text: SIZES.LARGE,
        icon: "mdi-size-l"
      },
    ]
  }),
  methods: {
    ...mapActions("sizeUI", {
      setSize: actionTypes.SET_SIZE
    })
  },
  computed: {
    ...mapGetters("sizeUI", {
      isLarge: getterTypes.IS_LARGE
    })
  }
};
